import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import '../../global.css';
import CustomPagination from '../../ReUsableComponent/Pagination';
import Loader from '../../ReUsableComponent/Loader';
import ExportIcon from '../../assets/svg/downloadIcon.svg';
import ImportIcon from '../../assets/svg/uploadIcon.svg';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter'
import { userTableHeader } from '../TableHeaders';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../ReUsableComponent/CustomDropzone';
import SecondaryButton from '../../ReUsableComponent/SecondaryButton';
import WellDoneIcon from "../../assets/lottie/welldone.json";
import SuccessModal from "../../ReUsableComponent/SuccesModal";
import Lottie from 'lottie-react';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { userManagementActions } from '../../store/reducers/UserManagementReducer';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { CreateBulkUsers, GetUserList, GetTemeHierarchyManagerList, DownloadUserMangmentReports } from './TeamManagement.service';
import ProgressBarForFileUpload from '../../ReUsableComponent/ProgressBarForFileUpload';
import { DownloadTemplate } from '../../Configurations/Core.service';
import HierarchyManagerCircle from './HierarchyManagerCircle';
import { RefreshOutlined } from '@mui/icons-material';
import { saveAs } from 'file-saver';



const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]

const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const TeamManagement = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [loading, setLoading] = React.useState(false);
    const selectedPage = useSelector(state => state.userManagement.selectedPage);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [openFileUploadedModal, setOpenFileUploadedModal] = useState(false);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const [displayData, setDisplayData] = useState([]);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [userTableData, setUserTableData] = useState([]);
    const [progress, setProgress] = useState(10);
    const openDownloadType = Boolean(anchorElForExport);
    const userId = useSelector((state) => state.userAuthentication.userId);
    const filterModel = useSelector((state) => state.userManagement.tableFilters);

    const [startTime, setStartTime] = useState(null);
    const [uploadSpeed, setUploadSpeed] = useState(null);

    const [reporteeLevels, setReporteeLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(0);

    const [currentData, setCurrentData] = useState([]);
    const [previousData, setPreviousData] = useState(null);

    const [previousId, setPreviousId] = useState([]);

    const [sownloadReporteeId, setDownloadReporteeId] = useState(userId);

    const defaultColDef = useMemo(() => {
        return {
            // editable: true,
            // filter: 'agSetColumnFilter'
        };
    }, []);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        getAllUserList('', '', selectedPage, userId);
    }, [selectedPage])

    const handlePageChange = (page) => {
        dispatch(userManagementActions.setSelectedPage({ type: page }));
    }

    const getAllUserList = (user_id, user_status, page, hirearchyUserId) => {
        setLoading(true);
        let queryParams = `?page=${page}&limit=${20}&user_id=${user_id}&user_status=${user_status}&hirearchy_user=${hirearchyUserId}`
        GetUserList(queryParams, USER_TOKEN).then(response => {
            let tableData = [];
            response?.data?.data?.map(item => {
                let row = {
                    userName: item?.name ? item?.name : '-',
                    designation: item?.designation_id ? item?.designation_id : '-',
                    mobileNumber: item?.mobile_number ? `+91 ${item?.mobile_number}` : '-',
                    experience: item?.year_exp ? `${item?.year_exp} Years` : '-',
                    city: item?.city ? item?.city : '-',
                    region: item?.region ? item?.region : 'NA',
                    storeCount: item?.door_count ? item?.door_count : '-',
                    nameStore: (item?.store_detail?.length > 0 ? (item?.store_detail[0].store_trade_name ? item?.store_detail[0].store_trade_name : item?.store_detail[0].store_name) : (item?.store_name ? item?.store_name : '-')),
                    nameBrand: arrayCheck(item?.brand_name),
                    status: (item?.user_status === true || item?.user_status === false) ? item?.user_status : item?.user_status.toUpperCase() === "ACTIVE" ? true : false,
                    id: item?._id
                }
                tableData.push(row)
                // console.log(item?.brand_name[0].brand_name, '>>>>>>>>>>><<<<<<<<<<<<<<<<<<')
            })
            setUserTableData(tableData);
            setTotalUserCount(response?.data?.totalCount)
            setDisplayData(userTableData.slice(0, 20))
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const arrayCheck = (item) => {
        if (typeof item === 'object' && item[0]?.brand_name) {
            return item[0]?.brand_name;
        } else if (typeof item[0] === 'string' && item[0] !== '') {
            return item[0];
        } else {
            return '-';
        }
    }

    React.useEffect(() => {
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        getTemeHierarchyManagerListById();
    }, [])

    const getEndDate = (startDate) => {
        const originalDate = new Date(startDate);
        let pastYear = originalDate.getFullYear();
        originalDate.setFullYear(pastYear + 1);
        originalDate.setMonth(2);
        originalDate.setDate(31);
        const formattedEndDate = originalDate.toISOString().substring(0, 10);
        return formattedEndDate
    }

    const onClickImport = () => {
        setOpenUploadFileModal(true);
    }

    const onClickCreateUser = () => {
        dispatch(userManagementActions.setSelectedBrands({ type: [] }));
        dispatch(userManagementActions.setSelectedStores({ type: [] }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        history.push('/app/createUser');
    }

    const removeFile = () => {
        setUploadedFile([]);
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month} -${day < 10 ? '0' : ''}${day} `;
        return moment(dateString).format("DD-MMM-YYYY");
    }


    const downloadUserTemplate = () => {
        setLoading(true);
        let param = `?template_type=user_template`
        DownloadTemplate(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.location.href = response.data.data
                ToastSuccessService('Template Downloaded Successfully.')
            } else {
                ToastErrorService(response.data.message);
            }
            setOpenUploadFileModal(false);

        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickUploadUser = () => {
        setLoading(true)
        let param = `?user_id=${userId} `
        const formData = new FormData();
        formData.append("file", uploadedFile);
        let fileSize = (uploadedFile?.size / (1024 * 1024)).toFixed(2)
        if (fileSize > 5) {
            ToastErrorService('Please select the file less than 5mb');
        } else {
            CreateBulkUsers(formData, param, USER_TOKEN).then(response => {
                if (response?.data?.statusCode === 400) {
                    ToastErrorService(response?.data?.message);
                } else {
                    setOpenFileUploadedModal(true);
                }
            }).catch(error => {
                ToastErrorService(error.response.data.message)
            }).finally(() => {
                setLoading(false);
            })
        }
    };

    const onClickUserEdit = (user_id) => {
        dispatch(userManagementActions.setSelectedTab({ type: 0 }));
        dispatch(userManagementActions.setViewUserDetailsTabLabel({ type: 'User Personal Information' }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        dispatch(userManagementActions.setIsEditFormVisible({ type: true }));
        getUserDetailById(user_id, 'edit');
        dispatch(userManagementActions.setSelectedStores({ type: [] }));
        dispatch(userManagementActions.setSelectedBrands({ type: [] }));
    }

    const getUserDetailById = (user_id, action) => {
        setLoading(true);
        let param = `?user_id=${user_id} `
        GetUserList(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                dispatch(userManagementActions.setViewUserDetailData({ type: response?.data?.data }));
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message)
        }).finally(() => {
            if (action === 'view') {
                history.push('/app/viewUserDetails');
            } else {
                history.push('/app/editUserDetails');
            }
        })
    }

    const onClickUserView = (user_id) => {
        dispatch(userManagementActions.setSelectedTab({ type: 0 }));
        dispatch(userManagementActions.setViewUserDetailsTabLabel({ type: 'User Personal Information' }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        getUserDetailById(user_id, 'view');
    }

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];

        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenUploadFileModal(false);
        }
    };
    const onClickDownloadTemplate = () => {
        downloadUserTemplate();
    }
    const onClickDownloadUserList = (event) => {
        setAnchorElForExport(event.currentTarget);
    }
    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            downloadUserTemplate();
        } else {
            downloadUserMangmentReportsData();
        }
        setAnchorElForExport(null);
    }
    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onGridReady = (params) => {
        params.api.setFilterModel(filterModel);
    };

    const onFilterChanged = (event) => {
        dispatch(userManagementActions.setTableFilters({ type: event.api.getFilterModel() }));
    };

    const onClickOk = () => {
        setOpenFileUploadedModal(false);
        setOpenFileSelectedModal(false);
        getAllUserList('', '', selectedPage, userId);
    }

    const changeUserStatus = (event) => {
        setLoading(true);
        let status = !event.status
        let queryParam = `?user_id=${event.id}&user_status=${status ? 'active' : 'inactive'}`
        GetUserList(queryParam, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                ToastSuccessService(response.data.message);
                getAllUserList('', '', selectedPage, userId);
                dispatch(userManagementActions.setSelectedPage({ type: 1 }));
            }
        }).catch((error) => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }


    // Teme Hierarchy Manager List
    const getTemeHierarchyManagerListById = () => {
        setLoading(true);
        let queryParam = `?user_id=${userId}&page=${selectedPage}&limit=${20}`
        GetTemeHierarchyManagerList(queryParam, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const getNewReportees = {
                    reportees: [response?.data?.data]
                }
                setReporteeLevels([getNewReportees?.reportees || []]);
                setCurrentLevel(0);
                setPreviousId([response?.data?.data?.Id]);
            }
        }).catch((error) => {
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTableData = (currentLevel, id) => {
        if (currentLevel > 0) {
            getAllUserList('', '', selectedPage, id);
            setDownloadReporteeId(id);
        }
    }

    const handleBackClick = (level) => {
        setCurrentLevel(level);
        getAllUserList('', '', selectedPage, previousId[level]);
        setDownloadReporteeId(previousId[level]);
    };

    const onClickReset = () => {
        const gridApi = gridRef.current.api;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
    }

    const downloadUserMangmentReportsData = () => {
        setLoading(true);
        let queryParam = `?hirearchy_user=${sownloadReporteeId}&name=${filterModel?.userName ? filterModel?.userName?.filter : ''}&designation_id=${filterModel?.designation ? filterModel?.designation?.filter : ''}&city=${filterModel?.city ? filterModel?.city?.filter : ''}&region=${filterModel?.region ? filterModel?.region?.filter : ''}&store_name=${filterModel?.nameStore ? filterModel?.nameStore?.filter : ''}&brand_name=${filterModel?.nameBrand ? filterModel?.nameBrand?.filter : ''}`;
        DownloadUserMangmentReports(queryParam, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const responseInUnit = new Uint8Array(response.data.excelBuffer.data);
                const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
                saveAs(blob, response.data.filename);
                ToastSuccessService('User report downloaded successfully.');
            }
        }).catch((error) => {
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })

    }

    return (
        <div>
            <div className='mainContainer'>
                <div className='notoSansFont containerDiv hierarchyHeaderMang '>
                    <div className='filterMainDiv'>
                        {reporteeLevels?.length !== 0 &&
                            <HierarchyManagerCircle
                                handleTableData={handleTableData}
                                handleBackClick={handleBackClick}
                                setReporteeLevels={setReporteeLevels}
                                reporteeLevels={reporteeLevels}
                                setCurrentLevel={setCurrentLevel}
                                currentLevel={currentLevel}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                                previousData={previousData}
                                setPreviousData={setPreviousData}
                                previousId={previousId}
                                setPreviousId={setPreviousId}
                            />
                        }
                    </div>
                    <div className='d-flex justify-content-end align-items-center '>
                        <button onClick={onClickReset} className='me-2 reset_filters_button'>
                            <span className='me-1 reset_filters_text'>Reset Filters</span>
                            <RefreshOutlined className='RefreshOutlined' />
                        </button>
                        <button className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                            <div className="cursorPointer">
                                <img src={ImportIcon} alt="" />
                            </div>
                        </button>
                        <button className='me-3 importExportFile' title='Download' onClick={onClickDownloadUserList}>
                            <div>
                                <img src={ExportIcon} alt="" />
                            </div>
                        </button>
                        <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                            onChange={onSelectDownloadCategory} category={'Category'} />
                        <div>
                            <CustomBlueButton title={<div className='d-flex align-items-center'>
                                <span className='fontSize18 fontBold pr-13px'>+</span>
                                <div>Create User</div>
                            </div>} onClick={onClickCreateUser} />
                        </div>
                    </div>
                </div>
                <div className='paddingTop24'>
                    <div className='tableContainer'>
                        <CustomTableWithFilter refGrid={gridRef} totalRow={() => { }} onFilterChanged={onFilterChanged} filterModel={filterModel} onGridReady={onGridReady} column={userTableHeader} tableRowData={userTableData} filter={defaultColDef} handleActiveToggle={changeUserStatus} onClickEdit={onClickUserEdit} onClickView={onClickUserView} customeHeightClass={'tableCustomeHeightClass'} />

                    </div>
                    <div className='paddingTop16 d-flex justify-content-end customPagination'>
                        <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalUserCount} />
                    </div>
                </div>
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openUploadFileModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUploadFileModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.csv', '.xlsx']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={handleFileDrop} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.csv', '.xlsx']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={onClickUploadUser} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessModal style={SuccessUploadModalStyle} open={openFileUploadedModal} handleClose={null} content={<div className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Lottie className="welldoneLottie" animationData={WellDoneIcon} loop={false} />
                </div>
                <div className='fontBold textBlack fontSize18 paddingTop24'>File Uploaded Successfully!!!</div>
                <div className='fontSize12 pt-2'>We will be processing the data in background, you can continue with your work.</div>
                <div className='pt-4 d-flex justify-content-center align-items-center'>
                    <button className='modalButton'
                        onClick={onClickOk}>OK</button>
                </div>
            </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default TeamManagement
