import React, { useState, useEffect } from 'react'
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import moment from 'moment';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import { IncetiveDashboardTableHeader } from '../../TableHeaders';
import Loader from '../../../ReUsableComponent/Loader';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './IncentiveDashboard.css';
import { incentiveActions } from '../../../store/reducers/IncentiveReducer';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import { DownloadUserIncentive, GetBrandNameList, GetIncentiveHistory, GetUserIncentive } from '../Insentive.service';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import CalendarIcon from '../../../assets/svg/calendar_2_fill.svg';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import IncentiveHistory from './IncentiveHistory';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';

const IncentiveDashboard = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const selectedPage = useSelector(state => state.brandManagement.selectedPage);

    const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }];

    const [incentiveActiveTabBtn, setIncentiveActiveTabBtn] = useState(useSelector(state => state.incentive.incetiveActiveTabBtn));
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const openDownloadType = Boolean(anchorElForExport);
    const [incentiveTableData, setIncentiveTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const [brandList, setBrandList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const defaultBrand = useSelector(state => state.incentive.defaultBrand);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [dropSearchValue, setdropSearchValue] = React.useState('');
    const [startDateDisplay, setStartDateDisplay] = useState(firstDayOfMonth);
    const [endDateDisplay, setEndDateDisplay] = useState(today);
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(today);
    const [selectedRange, setSelectedRange] = useState([]);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [activeTabBtn, setactiveTabBtn] = useState(1);
    const [billType, setBillType] = useState('wow_bill');
    const [incentiveHistoryData, setIncentiveHistoryData] = useState([]);
    const [incentiveViewData, setIncentiveViewData] = useState([]);
    const [openViewIncentiveModal, setOpenViewIncentiveModal] = useState(false);
    const convertDateIntoMonth = (dateString) => {
        let date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    }
    const [selectedMonthYear, setSelectedMonthYear] = useState(convertDateIntoMonth(new Date()));
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/incentive' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Incentive' }));
        if (incentiveActiveTabBtn === 1) {
            if (defaultBrand?.value) {
                getIncentives(defaultBrand?.value, selectedMonthYear);
            }
        } else if (incentiveActiveTabBtn === 2) {
            getIncentiveHistory(billType, defaultBrand?.value, startDate, endDate);
        }
    }, [defaultBrand, selectedMonthYear, incentiveActiveTabBtn, billType])

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const onClickTabBtn = (value) => {
        setactiveTabBtn(value);
        if (value === 1) {
            setBillType('wow_bill');
        } else if (value === 2) {
            setBillType('monthly_bill');
        } else {
            setBillType('special_bill');
        }
    }

    const getIncentiveHistory = (bill_type, brand, from_date, to_date) => {
        setLoading(true);
        let param = `?incentive_type=${bill_type}&brand=${brand}&fromDate=${from_date}&toDate=${to_date}`;
        GetIncentiveHistory(param).then(response => {
            setIncentiveHistoryData(response?.data?.data)
        }).catch(error => {
            ToastErrorService(error?.response?.data?.message)
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        dispatch(userAuthActions.setHeaderName({ type: 'Incentive' }));
        dispatch(userAuthActions.setActivePage({ type: '/incentive' }));
        getAllBrand();
    }, []);

    const valueCheck = (value) => {
        if (value) {
            return value;
        } else if (typeof value === 'string') {
            return '-';
        } else {
            return 0;
        }
    }

    const getIncentives = (brand, sale_month) => {
        setLoading(true);
        let param = `?page=${selectedPage}&limit=20&sales_month=${sale_month}&brand=${brand}`
        GetUserIncentive(param, USER_TOKEN).then(response => {
            let tableData = [];
            setTotalRecords(response?.data?.data?.totalRecords);
            response?.data?.data?.results?.map(item => {
                let row = {
                    employeeName: valueCheck(item?.sales_person_name),
                    designation: valueCheck(item?.sales_person_designation),
                    nameStore: valueCheckForName(item.store_name, item.store_trade_name),
                    nameBrand: valueCheck(item?.brand_name),
                    month: valueCheck(item?.sales_month),
                    noOfBill: valueCheck(item?.total_bill_no),
                    quantity: valueCheck(item?.total_quantity),
                    MRPValue: `₹${valueCheck(item?.total_mrp.toLocaleString('en-IN'))}`,
                    target: valueCheck(item?.target_value),
                    achievementPerCent: valueCheck(item?.achievement_percent).toFixed(2),
                    monthlyIncentive: valueCheck(item?.monthly_bill),
                    WOWBillIncentive: valueCheck(item?.wow_bill),
                    specialIncentive: valueCheck(item?.special_bill),
                    totalIncentive: valueCheck(item?.total_incentive),
                }
                tableData.push(row)
            })
            setIncentiveTableData(tableData);
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const downloadIncentives = (brand, sale_month) => {
        setLoading(true);
        let param = `?page=${selectedPage}&limit=20&sales_month=${sale_month}&brand=${brand}`
        DownloadUserIncentive(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 400) {
                ToastErrorService(response?.data?.message);
            } else if (response?.data?.buffer) {
                const responseInUnit = new Uint8Array(response?.data?.buffer?.data);
                const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
                saveAs(blob, response?.data?.filename);
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const convertDates = (date) => {
        const startDate = new Date(date);
        const yyyy = startDate.getFullYear();
        const mm = String(startDate.getMonth() + 1).padStart(2, "0");
        const dd = String(startDate.getDate()).padStart(2, "0");
        const convertedDate = `${yyyy}-${mm}-${dd}`;
        return convertedDate
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }


    const onClickIncentiveBtn = (value) => {
        setIncentiveActiveTabBtn(value);
        // dispatch(incentiveActions.setIncetiveActiveTabBtn({ type: value }))
    }

    const onClickExport = (event) => {
        setAnchorElForExport(event.currentTarget);
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onSelectDownloadUserIncentive = (event) => {
        const { value } = event.target
        if (value.toLowerCase() === 'report') {
            downloadIncentives(defaultBrand?.value, selectedMonthYear);
        } else {
            alert('Template download coming soon.')
        }
        setAnchorElForExport(null);
    }

    const onClickCreateIncetive = () => {
        history.push('/app/createIncentive');
    }

    const onGridReady = (params) => {

    };

    const handlePageChange = (pageValue) => {
        dispatch(incentiveActions.setSelectedPage({ type: pageValue }));
    }

    const getIncentiveList = () => {

    }

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setAnchorElForBrand(null);
                dispatch(incentiveActions.setDefaultBrandName({ type: brand?.label }));
                dispatch(incentiveActions.setDefaultBrand({ type: brand }));
            }
        });
    }

    const valueCheckForName = (storeName, storeTradeName) => {
        console.log(storeName, storeTradeName)
        if (storeTradeName && storeTradeName !== 'null') {
            return storeTradeName
        } else if (storeName !== 'null' && storeName !== '') {
            return storeName
        } else {
            return '-'
        }
    }

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
                if (!defaultBrand?.value) {
                    dispatch(incentiveActions.setDefaultBrand({ type: brands[0] }));
                    dispatch(incentiveActions.setDefaultBrandName({ type: brands[0]?.label }));
                }
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };
    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date > startDate) {
            setEndDate(date);
            setEndDateDisplay(date);
            setSelectedRange([startDate, date]);
            setCalendarOpen(false);
            getIncentiveHistory(billType, defaultBrand?.value, startDate, date)
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            setStartDateDisplay(date);
        }
    };

    const onDateSelect = (value) => {
        setSelectedMonthYear(convertDateIntoMonth(value));
    }

    const onClickView = (id) => {
        let viewData = incentiveHistoryData.find(incentive => incentive?._id === id)
        setIncentiveViewData(viewData)
        setOpenViewIncentiveModal(true);
    }

    return (
        <div className='dashboard-container notoSansFont'>
            <div className='d-flex justify-content-between filter-cont'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='cat-season-btn-cont incentiveToggalCont fontSize12'>
                        <button onClick={() => onClickIncentiveBtn(1)} className={`${incentiveActiveTabBtn === 1 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>User Incentive</button>
                        <button onClick={() => onClickIncentiveBtn(2)} className={`${incentiveActiveTabBtn === 2 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Incentive History</button>
                    </div>
                </div>
                <div className='d-flex align-items-center IncentiveFilterCont'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={defaultBrand.label}>{defaultBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={defaultBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                    {incentiveActiveTabBtn === 1 && <div className='me-2'>
                        <CustomMonthYearPicker disabled={false} value={selectedMonthYear} defaultValue={dayjs(selectedMonthYear)} onDateChange={(newValue) => onDateSelect(newValue)} required customClass={'customeClass'} />
                    </div>}
                    {incentiveActiveTabBtn === 2 && <div className='d-flex'>
                        <div className='position-relative me-3'>
                            <button className={`d-flex dateRangeFilter cursorPointer align-items-center justify-content-between`}
                                onClick={openCalendar} >
                                <img src={CalendarIcon} alt="" />
                                {(startDateDisplay && endDateDisplay) ? <div>{`${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}`}</div> : <div className='text-center w-100'>Select Date</div>}
                            </button>
                            <div className=''>
                                <CustomDateRangePicker startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={handleDateRangeSelect}
                                    closeOnOutSide={() => setCalendarOpen(false)} />
                            </div>
                        </div>
                    </div>}
                    {incentiveActiveTabBtn === 1 &&
                        <div className='me-3 importExportFile' title='Download' onClick={onClickExport}>
                            <img src={ExportIcon} alt="" />
                        </div>
                    }
                    <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                        onChange={onSelectDownloadUserIncentive} category={'Category'} />
                    <div>
                        <CustomBlueButton title={<div className='d-flex align-items-center'>
                            <span className='fontSize18 fontBold pr-13px'>+</span>
                            <div>Create Incentive</div>
                        </div>} onClick={onClickCreateIncetive} />
                    </div>
                </div>
            </div>


            {incentiveActiveTabBtn === 1 && <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter column={IncetiveDashboardTableHeader} totalRow={() => { }} tableRowData={incentiveTableData} onGridReady={onGridReady} customeHeightClass={'tableCustomeHeightClass'} />
                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalRecords} />
                </div>
            </div>}

            {incentiveActiveTabBtn === 2 && <IncentiveHistory incentiveHistory={incentiveHistoryData} onClickView={onClickView}
                onClickTabBtn={onClickTabBtn} incentiveType={billType} openViewModal={openViewIncentiveModal} setModalVisible={setOpenViewIncentiveModal}
                viewIncentiveHistoryData={incentiveViewData} />}

            {/* Loader */}
            {loading && <Loader />}
        </div>
    )
}

export default IncentiveDashboard