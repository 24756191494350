import React, { useState, useRef, useEffect } from 'react';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter';
import { salesConsolidatedTableHeader, salesUnprocessedTableHeader } from '../TableHeaders';
import './StockOverview.css';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import { DownloadConsolidatedReportList, DownloadStockReports, DownloadUnprocssedReportList, GetConsolidatedReportList, GetUnprocessedReportList, RefreshUnprocssedReportList, UploadSalesAndStockReport } from '../SalesOverview/SalesOverview.service';
import Loader from '../../ReUsableComponent/Loader';
import moment from 'moment';
import timeMoment from 'moment-timezone';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImportIcon from '../../assets/svg/uploadIcon.svg';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../ReUsableComponent/CustomDropzone';
import SecondaryButton from '../../ReUsableComponent/SecondaryButton';
import ProgressBarForFileUpload from '../../ReUsableComponent/ProgressBarForFileUpload';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import { event } from 'jquery';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import { GetBrandNameList, GetStoreCustomerNameList } from '../RuleManagement/RuleManagement.service';
import CustomDatePicker from '../../ReUsableComponent/DatePickerComponent';
import dayjs from 'dayjs';
import PdfIcon from '../../assets/svg/pdf.svg';
import JPEG from '../../assets/svg/jpeg.svg';
import PNG from '../../assets/svg/png.svg';
import ExcellIcon from '../../assets/png/excellIcon.png';
import { responseMessages } from '../../Configurations/ResponseMessages';
import { DownlaodStockReport } from './StockOverview.service';


const MonthList = [{ label: 'January', value: 'January' }, { label: 'February', value: 'February' }, { label: 'March', value: 'March' }, { label: 'April', value: 'April' }, { label: 'May', value: 'May' },
{ label: 'June', value: 'June' }, { label: 'July', value: 'July' }, { label: 'August', value: 'August' }, { label: 'September', value: 'September' },
{ label: 'October', value: 'October' }, { label: 'November', value: 'November' }, { label: 'December', value: 'December' },
];

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const StockOverview = () => {

    const gridRef = useRef(null);
    const history = useHistory();
    const today = new Date();

    const [tableFilterValue, setTableFilterValue] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [anchorElForYear, setAnchorElForMonth] = React.useState(null);
    const openMonthFilter = Boolean(anchorElForYear);
    const [selectedMonth, setSelectedMonth] = React.useState({ label: 'January', value: 'January' });
    const [consolidatedReport, setConsolidatedReport] = useState([]);
    const [unprocessedReports, setUnprocessedReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [uploadedFileSales, setUploadedFileSales] = useState([]);
    const [uploadedFileStock, setUploadedFileStock] = useState([]);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [dropSearchValue, setdropSearchValue] = React.useState('');
    const [anchorElForStore, setAnchorElForStore] = useState(null);
    const openStoreFilter = Boolean(anchorElForStore);
    const [dropSearchValueStore, setdropSearchValueStore] = React.useState('');
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [storeNameList, setStoreNameList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedFilterDate, setSelectedFilterDate] = useState(today);
    const [selectedStoreName, setSelectedStoreName] = useState({ label: '', value: '' });
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    useEffect(() => {
        const date = new Date();
        const currentMonthName = MonthList[date.getMonth()];
        setSelectedMonth(currentMonthName);
        setLoading(true);
        getAllBrand();
        getStoreList('');
        getUnprocessedReportList(1, currentMonthName.value);
        getConsolidatedReportList(1, currentMonthName.value);
    }, [])

    const fileTypeIcon = (file) => {
        if (file === 'pdf') {
            return PdfIcon
        } else if (file === 'jpeg' || file === 'jpg') {
            return JPEG
        } else {
            return ExcellIcon
        }
    }

    const getUnprocessedReportList = (page, currentMonth) => {
        let params = `?reportType=Stock&page=${page}&limit=25`;
        let failedRecordCheck = (item) => {
            if (item.unprocessed_file_url) {
                return true;
            } else if (item.file_url) {
                return true;
            } else {
                return false;
            }
        }
        GetUnprocessedReportList(params, USER_TOKEN).then(response => {
            let dataForTable = [];
            if (response.data.statusCode === 200) {
                response.data.data.forEach(item => {
                    let row = {
                        date: convertDate(item.created_at),
                        emailId: item.sender_email,
                        failedRecord: failedRecordCheck(item),
                        failedRecordFile: item.unprocessed_file_url ? item.unprocessed_file_url : item.file_url
                    }
                    dataForTable.push(row);
                })
                setUnprocessedReports(dataForTable)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }
    const getConsolidatedReportList = (page, currentMonth) => {
        let params = `?report_type=Stock`
        GetConsolidatedReportList(params, USER_TOKEN).then(response => {
            let dataForTable = [];
            if (response.data.statusCode === 200) {
                response.data.data.brand_list.forEach(item => {
                    let row = {
                        month: item.stock_month,
                        nameBrand: item.brand_name,
                        passRecord: item.isGoodData,
                        errorRecord: item.isBadData,
                        brandId: item?.brand_id,
                        // updatedAt: item?.file_info ? convertDate(item?.file_info?.created_at) : '-'
                    }
                    dataForTable.push(row);
                })
                setConsolidatedReport(dataForTable)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };
    const handleStoreSearchChange = (event) => {
        setdropSearchValueStore(event.target.value);
    };

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                // dispatch(salesActions.setDefaultBrand({ type: brands[0]?.value }));
                setBrandList(brands);
                // setSelectedBrand({ label: brands[0]?.label, value: brands[0]?.value });
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })
    }

    const storeTradeNameCheck = (store, tradeName) => {
        if (tradeName && tradeName !== 'null') {
            return tradeName
        } else {
            return store
        }
    }

    const getStoreList = (brand_id) => {
        let queryParams = `?brand_id=${brand_id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: storeTradeNameCheck(item?.customer_name, item.item?.store_trade_name),
                    value: item?._id,
                }
                dropdownData.push(row);
            })
            setStoreNameList(dropdownData);
        }).catch(error => {
            // if (error.response.status === 401) {
            //     history.push('/')
            // }
            // ToastErrorService(error.response.data.message);
        });
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD-MMM-YYYY");
    };

    const onGridReady = (params) => {
        params.api.setFilterModel(tableFilterValue);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleMonthClick = (event) => {
        setAnchorElForMonth(event.currentTarget);
    };

    const handleMonthClose = () => {
        setAnchorElForMonth(null);
    };

    const onSelectMonth = (event) => {
        MonthList.forEach(year => {
            if (event.target.value === year?.value) {
                setSelectedMonth({ label: year?.label, value: event.target.value })
                setAnchorElForMonth(null);
                setLoading(true);
                getConsolidatedReportList(1, event.target.value);
                getUnprocessedReportList(1, event.target.value);
            }
        })
    }

    const unprocessedReportDownload = (fileUrl) => {
        setLoading(true);
        let param = `?file_url=${fileUrl}`
        DownloadUnprocssedReportList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.file_url, '_blank');
                ToastSuccessService('Stock report downloaded successfully.');
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickDownloadReport = (reportType, data) => {
        setLoading(true);
        let params = `?brand=${data.data.brandId}&report=Stock&stock_date=${moment(selectedFilterDate).format("YYYY-MM-DD")}`
        if (reportType === 'failedRecord') {
            unprocessedReportDownload(data.data.failedRecordFile);
        } else if (reportType === 'errorRecord') {
            params = params + '&service=bad_stocks';
        } else {
            params = params + '&service=good_stocks';
        }
        DownloadStockReports(params, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                window.open(response.data.fileUrl, '_blank');
                ToastSuccessService('Stock report downloaded successfully.');
            } else if (response?.data?.statusCode === 400) {
                ToastErrorService(response.data.message)
            }
            console.log(response)
        }).catch(error => {
            console.log(error)
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickImport = () => {
        setUploadedFileStock([]);
        setUploadedFileSales([]);
        setSelectedBrand({ label: '', value: '' });
        setSelectedStoreName({ label: '', value: '' });
        setOpenUploadFileModal(true);
    }

    const handleFileDrop = (files, fileFor) => {
        if (selectedBrand?.value && selectedStoreName?.value) {
            const fileUploaded = files[0];
            if (fileFor === 'stock') {
                if (fileUploaded) {
                    setUploadedFileStock(fileUploaded);
                    setProgress(0);
                    simulateUpload();
                    uploadStockAndSalesReport(fileFor, fileUploaded);
                }
            } else {
                if (fileUploaded) {
                    setUploadedFileSales(fileUploaded);
                    setProgress(0);
                    simulateUpload();
                    uploadStockAndSalesReport(fileFor, fileUploaded);
                }
            }
        } else {
            ToastErrorService('Please select the brand and store first.')
        }
    };

    const simulateUpload = () => {
        // setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
            }
        });
        getStoreList(event.target.value)
        // dispatch(salesActions.setDefaultBrand({ type: event.target.value }));
    }

    const removeFile = (fileOf) => {
        if (fileOf === 'stock') {
            setUploadedFileStock([]);
        } else {
            setUploadedFileSales([]);
        }
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };
    const handleStoreClick = (event) => {
        setAnchorElForStore(event.currentTarget);
    };
    const handleStoreClose = () => {
        setAnchorElForStore(null);
    };

    const onSelectStoreName = (event, value) => {
        storeNameList.forEach(store => {
            if (event.target.value === store?.value) {
                setSelectedStoreName({ label: store?.label, value: event.target.value })
                setAnchorElForStore(null);
            }
        });
    }

    const onDateSelect = (event) => {
        let date = new Date(event);
        setSelectedDate(date);
    }
    const onFilterDateSelect = (event) => {
        let date = new Date(event);
        setSelectedFilterDate(date);
    }

    const uploadStockAndSalesReport = (fileFor, file) => {
        // setLoading(true);
        const formData = new FormData;
        if (fileFor === 'stock') {
            formData.append('report_type', 'SOH');
        } else {
            formData.append('report_type', 'SLS');
        }
        formData.append('file', file);
        formData.append('brand_id', selectedBrand?.value);
        formData.append('store_id', selectedStoreName?.value);
        formData.append('report_date', timeMoment.utc(selectedDate).tz("Asia/Kolkata").format());
        UploadSalesAndStockReport(formData).then(response => {
            ToastSuccessService(`${response?.data?.message}`);
        }).catch(error => {
            if (error?.response) {
                ToastErrorService(error?.response?.data?.message);
            } else {
                ToastErrorService("Something went wrong, please try again.");
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='mainContainer'>
            <div className='notoSansFont d-flex justify-content-end'>
                <div className='filterMainDiv'>
                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                        <label className="cursorPointer">
                            <img src={ImportIcon} alt="" />
                        </label>
                    </div>
                    <div className='position-relative'>
                        <CustomDatePicker maxDateRange={dayjs(today)} disabled={false} defaultValue={dayjs(selectedFilterDate)} value={selectedFilterDate} onDateChange={onFilterDateSelect} required label='Select date*' />
                    </div>
                </div>
            </div>
            <div className='reportTableList'>
                <div>
                    <div className='montSerratFont fontSize18 pt-3 pb-3'>Stock Consolidated Report</div>
                    {consolidatedReport && <div className='tableContainer reports'>
                        <CustomTableWithFilter totalRow={() => { }} isContentLeftRightFixed={false} height={450} column={salesConsolidatedTableHeader} tableRowData={consolidatedReport} onClickEdit={null} onClickView={null} onFilterChanged={null} onClickDownloadReport={onClickDownloadReport} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} />
                    </div>}
                </div>
                <div>
                    <div className='montSerratFont fontSize18 pt-3 pb-3'>Unprocessed Report</div>
                    {unprocessedReports && <div className='tableContainer reports'>
                        <CustomTableWithFilter totalRow={() => { }} isContentLeftRightFixed={false} height={450} column={salesUnprocessedTableHeader} tableRowData={unprocessedReports} onClickEdit={null} onClickView={null} onFilterChanged={null} onClickDownloadReport={onClickDownloadReport} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} />
                    </div>}
                </div>
            </div>

            <CustomModal classes="customeProgressModal" open={openUploadFileModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUploadFileModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div>
                                <div className='position-relative max-w-200'>
                                    <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={handleBrandClick}>
                                        <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label ? selectedBrand?.label : 'Select Brand'}</p>
                                        <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </div>
                                    <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                                        onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                                        dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                </div>
                            </div>
                            <div>
                                <div className='position-relative max-w-200'>
                                    <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={handleStoreClick}>
                                        <p className='text-truncate' title={selectedStoreName?.label}>{selectedStoreName?.label ? selectedStoreName?.label : 'Select Store'}</p>
                                        <img className={`${openStoreFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </div>
                                    <CustomFilterSearchDropdown customClass='upload-file' data={storeNameList} handleClose={handleStoreClose} open={openStoreFilter} anchorEl={anchorElForStore}
                                        onChange={onSelectStoreName} selectedValue={selectedStoreName?.value} category={'Store'}
                                        dropSearchValue={dropSearchValueStore} setdropSearchValue={setdropSearchValueStore} handleSearchChange={handleStoreSearchChange} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <CustomDatePicker disabled={false} defaultValue={dayjs(selectedDate)} value={selectedDate} onDateChange={onDateSelect} required label='Select date*' />
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer gap-4'>
                            <div className='w-50'>
                                {uploadedFileSales?.length < 1 ? <CustomDropzone onFileDrop={(e) => handleFileDrop(e, 'sales')} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='fontSemibold fontSize18 textColorBlack pb-3'>Select Sales File</div>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={(e) => handleFileDrop(e, 'sales')} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                                    : <div className='uploaded-file-cont'>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={fileTypeIcon(uploadedFileSales?.fileUploaded?.path?.split('.')[1])} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Sales File</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('sales')} progress={100} File={uploadedFileSales} uploadSpeed={''} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='w-50'>
                                {uploadedFileStock?.length < 1 ? <CustomDropzone onFileDrop={(e) => handleFileDrop(e, 'stock')} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='fontSemibold fontSize18 textColorBlack pb-3'>Select Stock File</div>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={(e) => handleFileDrop(e, 'stock')} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                                    : <div className='uploaded-file-cont'>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={fileTypeIcon(uploadedFileStock?.fileUploaded?.path?.split('.')[1])} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Stock File</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('stock')} progress={100} File={uploadedFileStock} uploadSpeed={''} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={false} onClick={onClickUpload} title={'Upload'} />
                                    </div>
                                </div>}
                            </div> */}
                        </div>
                    </div>
                    <div className='paddingTop24 d-flex justify-content-center'>
                        <div className='widthFit'>
                            <CustomBlueButton customCreatePropsBtnClass="custom-close-btn" disabled={false} onClick={() => setOpenUploadFileModal(false)} title={'Close'} />
                        </div>
                    </div>
                </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default StockOverview
