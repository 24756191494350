import React, { useEffect, useRef, useState } from 'react';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import { brandTableHeader, ItemMasterHeader, ReconReportHeader } from '../../TableHeaders';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import Loader from '../../../ReUsableComponent/Loader';
// import { DownaloadBrandItemMaster, GetBrandItemMaster, UploadBrandMasterList } from '../BrandManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
// import { GetBrandNameList } from '../../RuleManagement/RuleManagement.service';
import { saveAs } from 'file-saver';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import { salesActions } from '../../../store/reducers/SalesOverviewReducer';
import { DownloadConsolidatedReportList, GetSalesReconData, RefreshUnprocssedReportList } from '../SalesOverview.service';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import dayjs from 'dayjs';
// import { DownloadTemplate } from '../../../Configurations/Core.service';

const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]
const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const MonthList = [{ label: 'January', value: 'January' }, { label: 'February', value: 'February' }, { label: 'March', value: 'March' }, { label: 'April', value: 'April' }, { label: 'May', value: 'May' },
{ label: 'June', value: 'June' }, { label: 'July', value: 'July' }, { label: 'August', value: 'August' }, { label: 'September', value: 'September' },
{ label: 'October', value: 'October' }, { label: 'November', value: 'November' }, { label: 'December', value: 'December' },
];


const ReconReport = () => {
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const [reconReportTableData, setReconReportTableData] = useState([]);
    const openDownloadType = Boolean(anchorElForExport);
    const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const selectedPage = useSelector(state => state.brandManagement.selectedPageForMatser);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [selectedBrand, setSelectedBrand] = useState({ label: useSelector(state => state?.sales?.defaultBrandForRecon?.label), value: useSelector(state => state?.sales?.defaultBrandForRecon?.value) });
    const [brandList, setBrandList] = useState([]);
    const defaultBrand = useSelector(state => state?.sales?.defaultBrandForRecon);
    const itemMasterFilter = useSelector(state => state?.brandManagement?.masterFilter);
    const [anchorElForYear, setAnchorElForMonth] = useState(null);
    const openMonthFilter = Boolean(anchorElForYear);
    const [selectedMonth, setSelectedMonth] = useState({ label: useSelector(state => state?.sales?.defaultMonthForRecon?.label), value: useSelector(state => state?.sales?.defaultMonthForRecon?.value) });
    const [dropSearchValue, setdropSearchValue] = useState('');
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const convertDateIntoMonth = (dateString) => {
        let date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' })
        return `${month}-${year}`;
    }
    const [selectedMonthYear, setSelectedMonthYear] = useState(convertDateIntoMonth(new Date(useSelector(state => state?.sales?.defaultMonthForRecon))));
    useEffect(() => {
        getAllBrand();
        // const date = new Date();
        // const currentMonthName = MonthList[date.getMonth() - 1];
        // setSelectedMonth(currentMonthName);
        // brandList.forEach(brand => {
        //     if (defaultBrand === brand?.label) {
        //         setSelectedBrand({ label: brand?.label, value: defaultBrand })
        //         setAnchorElForBrand(null);
        //     }
        // });
    }, [])
    useEffect(() => {
        getStoreList(selectedBrand?.value)
    }, [defaultBrand])


    useEffect(() => {
        let param = '';
        if (itemMasterFilter?.barcode) {
            param = param + `&barcode=${itemMasterFilter?.barcode?.filter}`;
        }
        if (itemMasterFilter?.styleCode) {
            param = param + `&style_code=${itemMasterFilter?.styleCode?.filter}`;
        }
        if (itemMasterFilter?.categoryDivision) {
            param = param + `&category_division=${itemMasterFilter?.categoryDivision?.filter}`;
        }
        if (itemMasterFilter?.categoryGroup) {
            param = param + `&category_group=${itemMasterFilter?.categoryGroup?.filter}`;
        }
        if (itemMasterFilter?.categorySection) {
            param = param + `&category_presence=${itemMasterFilter?.categorySection?.filter}`;
        }
        // getBrandMasterList(param);
    }, [selectedPage, defaultBrand, itemMasterFilter])


    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            onClickDownloadTemplate();
        } else {
            let requestBody = ''
            downloadBrandMasterReport(requestBody);
        }
        setAnchorElForExport(null);
    }
    const handleMonthClick = (event) => {
        setAnchorElForMonth(event.currentTarget);
    };

    const handleMonthClose = () => {
        setAnchorElForMonth(null);
    };
    const onSelectMonth = (event) => {
        MonthList.forEach(year => {
            if (event.target.value === year?.value) {
                setSelectedMonth({ label: year?.label, value: event.target.value })
                setAnchorElForMonth(null);
                // setLoading(true);
            }
        })
    }

    const onClickDownloadTemplate = () => {
        // setLoading(true);
        // let param = `?template_type=item_master_templete`
        // DownloadTemplate(param, USER_TOKEN).then(response => {
        //     if (response.data.statusCode === 200) {
        //         window.location.href = response.data.data
        //         ToastSuccessService('Template Downloaded Successfully.')
        //     } else {
        //         ToastErrorService(response.data.message);
        //     }
        //     // setOpenUploadFileModal(false);
        // }).catch(error => {
        //     ToastErrorService('Something went wrong, Please try again after sometime.');
        // }).finally(() => {
        //     setLoading(false);
        // })
    }
    const downloadBrandMasterReport = (body) => {
        // let param = `?brand_name=${defaultBrand}`
        // DownaloadBrandItemMaster(param).then(response => {
        //     const responseInUnit = new Uint8Array(response.data.buffer.data);
        //     const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
        //     saveAs(blob, response.data.filename);
        //     if (response?.data?.buffer?.statusCode === 400) {
        //         ToastErrorService(response?.data?.buffer?.message);
        //     } else {
        //         ToastSuccessService('Report downloaded successfully.');
        //     }
        // }).catch(error => {
        //     if (error.response.status === 400) {
        //         ToastErrorService(error?.response?.data?.message);
        //     } else {
        //         ToastErrorService('Something went wrong, Please try again after sometime.');
        //     }
        //     if (error.response.status === 401) {
        //         history.push('/')
        //     }
        // }).finally(() => {
        //     setLoading(false);
        // })
    }

    const onClickDownloadReport = (event) => {
        setAnchorElForExport(event.currentTarget)
    }

    const handlePageChange = (pageValue) => {
        dispatch(brandManagementActions.setSelectedPageForMatser({ type: pageValue }));
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onClickImport = () => {
        setOpenFileUploadModal(true);
    }
    const onGridReady = (params) => {
        // params.api.setFilterModel(itemMasterFilter);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenFileUploadModal(false);
        }
    };

    const uploadBulkList = () => {
        // setLoading(true);
        // const formData = new FormData();
        // formData.append('file', uploadedFile);
        // UploadBrandMasterList(formData, USER_TOKEN).then(response => {
        //     if ((response?.status === 200 || response?.status === 201) && response?.data?.statusCode !== 400) {
        //         setOpenSuccessPopup(true);
        //         setOpenFileSelectedModal(false);
        //         setTimeout(() => {
        //             setOpenSuccessPopup(false);
        //         }, 5000)
        //     } else if (response?.data?.statusCode === 400) {
        //         ToastErrorService(response?.data?.message)
        //     }

        // }).catch(error => {
        // }).finally(() => {
        //     setLoading(false);
        // })
    };

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000;
                const speed = (nextProgress / elapsedTime).toFixed(2);
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
                dispatch(salesActions.setDefaultBrandForRecon({ type: brand }));
            }
        });
        // getStoreList(event.target.value)
    }

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
                // getStoreList(brands[0]?.value)
                // dispatch(salesActions.setDefaultBrandForRecon({ type: brands[0] }));
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value;
        } else {
            return '-';
        }
    }

    const getStoreList = (brand_id) => {
        setLoading(true);
        let param = `?brand_id=${brand_id}`;
        GetStoreCustomerNameList(param, USER_TOKEN).then(response => {
            let tableData = [];
            response?.data?.data?.map(item => {
                let row = {
                    codeStore: valueCheck(item?.store_code),
                    nameStore: valueCheck((item?.store_trade_name && item?.store_trade_name !== 'null') ? item?.store_trade_name : item?.customer_name),
                    email: valueCheck(item?.store_email_id),
                    id: item?._id
                }
                tableData.push(row)
            })
            setReconReportTableData(tableData);
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const onFilterChanged = (event) => {
        let filterModel = event.api.getFilterModel();
        dispatch(brandManagementActions.setMasterFilter({ type: filterModel }))
        // getStoreList(param);
    };

    const downloadReport = (event, action) => {
        setLoading(true);
        if (action === 'download') {
            let param = `?store=${event.data.id}&brand=${selectedBrand?.value}&report=Sales&service=recon`;
            if (event.colDef.field === 'reconFileDownload') {
                param = param + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear()
                param = param + `&view_range=${currentYear}`
            }
            DownloadConsolidatedReportList(param, USER_TOKEN).then(response => {
                if (response.data.statusCode === 200) {
                    window.open(response.data.presignedFileUrl, '_blank');
                    ToastSuccessService('Sales report downloaded successfully.');
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                ToastErrorService(error?.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            let param = `?store=${event.data.id}&brand=${selectedBrand?.value}&report=Sales&service=recon`;
            if (event.colDef.field === 'reconFileDownload') {
                param = param + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear()
                param = param + `&view_range=${currentYear}`
            }
            RefreshUnprocssedReportList(param, USER_TOKEN).then(response => {
                if (response.data.statusCode === 200) {
                    ToastSuccessService('Reports are being refreshed, please check after sometime.');
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const onMonthSelect = (value) => {
        setSelectedMonthYear(convertDateIntoMonth(value));
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-end'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                </div>
                <div className='position-relative'>
                    <CustomMonthYearPicker maxDateRange={dayjs()} disabled={false} value={selectedMonthYear} defaultValue={dayjs(selectedMonthYear)} onDateChange={(newValue) => onMonthSelect(newValue)} required customClass={'customeClass'} />
                </div>
            </div>
            <div className='pt-3'>
                <div className='tableContainer reports'>
                    <CustomTableWithFilter onFilterChanged={onFilterChanged} totalRow={() => { }} onClickDownloadReport={downloadReport} column={ReconReportHeader} tableRowData={reconReportTableData} onClickView={null} onGridReady={onGridReady} filterModel={null} customeHeightClass={'tableCustomeHeightClass'} />

                </div>
                {/* <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalCount} />
                </div> */}
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openFileUploadModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileUploadModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={uploadBulkList} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => setOpenSuccessPopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>File Uploaded Successfully!</div>
                    <div className='fontSize12 textColor333'>We will be processing the data in background, you can continue with your work.</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default ReconReport