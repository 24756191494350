export const storeTableHeader = [
    {
        headerName: "Store Name",
        field: 'storeName',
        sortable: true,
        width: 150,
        headerTooltip: 'Store Name',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        sortable: true,
        width: 130,
        headerTooltip: 'Brand Name',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Opening Status",
        field: 'openingStatus',
        sortable: true,
        // headerComponentFramework: OpeningStatusFilter,
        width: 170,
        headerTooltip: 'Opening Status',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Class",
        field: 'storeClass',
        sortable: true,
        width: 100,
        headerTooltip: 'Class',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Classification",
        field: 'classification',
        sortable: true,
        width: 140,
        headerTooltip: 'Classification',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "City",
        field: 'city',
        sortable: true,
        width: 100,
        headerTooltip: 'City',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Area",
        field: 'area',
        sortable: true,
        width: 100,
        headerTooltip: 'Area',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Business Model",
        field: 'businessModel',
        sortable: true,
        width: 160,
        headerTooltip: 'Business Model',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Channel",
        field: 'channel',
        sortable: true,
        width: 150,
        headerTooltip: 'Channel',
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Opening Date",
        field: 'openingDate',
        sortable: true,
        width: 150,
        headerTooltip: 'Opening Date',
        filter: false
    },
    {
        headerName: "Display Options",
        field: 'displayOptions',
        sortable: true,
        width: 120,
        headerTooltip: 'Display Options',
        filter: false,
    },
    {
        headerName: "Display Qty",
        field: 'displayQty',
        sortable: true,
        width: 120,
        headerTooltip: 'Display Qty',
        filter: false,
    },
    {
        headerName: "Fresh Margin Type",
        field: 'freshMarginType',
        sortable: true,
        width: 120,
        headerTooltip: 'Fresh Margin Type',
        filter: false,
    },
    {
        headerName: "Fresh Margin",
        field: 'freshMargin',
        sortable: true,
        width: 120,
        headerTooltip: 'Fresh Margin',
        filter: false,
    },
    {
        headerName: "EOSS Margin Type",
        field: 'eossMarginType',
        sortable: true,
        width: 120,
        headerTooltip: 'EOSS Margin Type',
        filter: false,
    },
    {
        headerName: "EOSS Margin",
        field: 'eossMargin',
        sortable: true,
        width: 120,
        headerTooltip: 'EOSS Margin',
        filter: false,
    },
    {
        headerName: "Profile Status",
        field: 'profileStatus',
        sortable: true,
        width: 150,
        headerTooltip: 'Profile Status',
        filter: false,
    },
    {
        headerName: "Action",
        field: 'action',
        sortable: false,
        filter: false
    },
];

export const brandTableHeader = [
    {
        headerName: "Brand Name",
        field: 'brandName',
        sortable: true,
        width: 150,
        headerTooltip: 'Brand Name',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Brand Deck",
        field: 'brandDeck',
        sortable: true,
        headerTooltip: 'Brand Deck',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Email Id",
        field: 'emailId',
        sortable: true,
        headerTooltip: 'Email Id',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "GSTIN",
        field: 'gstIn',
        sortable: true,
        headerTooltip: 'GSTIN',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "PAN No",
        field: 'pan',
        sortable: true,
        width: 150,
        headerTooltip: 'PAN Number',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Product Type",
        field: 'productType',
        sortable: true,
        width: 150,
        headerTooltip: 'Product Type',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Brand Ph.No",
        field: 'brandPhoneNo',
        sortable: true,
        width: 150,
        headerTooltip: 'Brand Ph.No',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Business Model",
        field: 'businessModel',
        sortable: true,
        width: 160,
        headerTooltip: 'Business Model',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Tek - Contact Name",
        field: 'productPersonName',
        sortable: true,
        headerTooltip: 'Tek - Contact Name',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Tek - Contact Phone No.",
        field: 'productPersonPhoneNo',
        sortable: true,
        width: 150,
        headerTooltip: 'Tek - Contact Phone No.',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Tek - Contact Department",
        field: 'productPersonDepartment',
        sortable: true,
        width: 150,
        headerTooltip: 'Tek - Contact Department',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Tek - Contact Email Id",
        field: 'productPersonEmailId',
        sortable: true,
        headerTooltip: 'Tek - Contact Email Id',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Brand Status",
        field: 'brandStatus',
        sortable: true,
        width: 150,
        headerTooltip: 'Brand Status',
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Profile Status",
        field: 'profileStatus',
        sortable: true,
        width: 150,
        headerTooltip: 'Profile Status',
        filter: false
    },
    {
        headerName: "Action",
        field: 'action',
        sortable: false,
        filter: false
    },
];

export const userTableHeader = [
    {
        headerName: "User Name",
        field: 'userName',
        sortable: true,
        filter: true
    },
    {
        headerName: "Designation",
        field: 'designation',
        sortable: true,
        filter: true
    },
    {
        headerName: "Mobile Number",
        field: 'mobileNumber',
        sortable: true,
        filter: true
    },
    {
        headerName: "Years Of Experience",
        field: 'experience',
        sortable: true,
        filter: true
    },
    {
        headerName: "City",
        field: 'city',
        sortable: true,
        filter: true
    },
    {
        headerName: "Region",
        field: 'region',
        sortable: true,
        filter: true
    },
    {
        headerName: "Store Count",
        field: 'storeCount',
        sortable: true,
        filter: true
    },
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        filter: true
    },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        sortable: true,
        filter: true
    },
    {
        headerName: "Action",
        field: 'action',
        sortable: false,
        filter: false,
    },
]

export const salesConsolidatedTableHeader = [
    // {
    //     headerName: "Month",
    //     field: 'month',
    //     // sortable: true,
    // },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
    },
    // {
    //     headerName: "Updated On",
    //     field: 'updatedAt',
    // },
    {
        headerName: "Consolidated Record",
        field: 'passRecord',
        // sortable: true,
    },
    {
        headerName: "Error Record",
        field: 'errorRecord',
        // sortable: true,
    },
]
export const salesRecConsolidatedTableHeader = [
    {
        headerName: "Month",
        field: 'month',
        // sortable: true,
    },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
    },
    {
        headerName: "Updated On",
        field: 'updatedAt',
        // sortable: true,
    },
    {
        headerName: "Consolidated Record",
        field: 'passRecord',
        // sortable: true,
        for: 'sales'
    },
    {
        headerName: "YTD Reports",
        field: 'ytdReport',
        // sortable: true,
        for: 'ytd'
    },
    {
        headerName: "Error Record",
        field: 'errorRecord',
        // sortable: true,
    },
    {
        headerName: "Recon Report",
        field: 'reconReport',
        // sortable: true,
    },
]

export const salesUnprocessedTableHeader = [
    {
        headerName: "Date",
        field: 'date',
        // sortable: true,
    },
    {
        headerName: "Email Id",
        field: 'emailId',
        // sortable: true,
    },
    {
        headerName: "Failed Record",
        field: 'failedRecord',
        // sortable: true,
    }
];

export const rulesMangmentViewTableHeader = [
    {
        headerName: "Report Type",
        field: 'reportTypeName',
        // width: 120
        // sortable: true,
    },
    // {
    //     headerName: "Brand Name",
    //     field: 'reportTypeBrandName',
    //     width: 150
    //     // width: 'calc(100% - 200px)',
    //     // sortable: true,
    // },
    {
        headerName: "Store Name",
        field: 'reportTypeStoreName',
        // width: 213,
        // width: 213
        // sortable: true,
    },
    {
        headerName: "Action",
        field: 'action',
        // width: 85,
        actionFor: 'rulesMangment',
        // width: 70,
        // sortable: true,
    },
];
export const feedbackTableHeader = [
    {
        headerName: "Employee ID",
        field: 'employeeId',
        sortable: true,
        headerTooltip: 'Employee ID',
        width: 150
    },
    {
        headerName: "Name",
        field: 'name',
        sortable: true,
        headerTooltip: 'Name'
    },
    {
        headerName: "Brand Name",
        field: 'feedbackBrandName',
        sortable: true,
        headerTooltip: 'Brand Name'
    },
    {
        headerName: "Store Name",
        field: 'feedbackStoreName',
        sortable: true,
        headerTooltip: 'Store Name'
    },
    {
        headerName: "Created Date",
        field: 'createdDate',
        sortable: true,
        headerTooltip: 'Created Date'
    },
    {
        headerName: "Area",
        field: 'area',
        sortable: true,
        headerTooltip: 'Area',
        width: 100
    },
    {
        headerName: "City",
        field: 'city',
        sortable: true,
        headerTooltip: 'City',
        width: 100
    },
    {
        headerName: "Title",
        field: 'title',
        sortable: true,
        headerTooltip: 'Title',
        width: 140
    },
    {
        headerName: "Feedback Status",
        field: 'feedbackStatus',
        // sortable: true,
        headerTooltip: 'Feedback Status',
        filter: false,
    },
    {
        headerName: "Action",
        field: 'feedbackAction',
        actionFor: 'feedback',
        filter: false,
        width: 120,
        // sortable: true,
    },
];

export const ProductSKUHeader = [
    // {
    //     headerName: "Brand Name",
    //     field: 'nameBrand',
    //     sortable: true,
    //     headerTooltip: 'Brand Name',
    // },
    {
        headerName: "Product",
        field: 'product',
        sortable: true,
        headerTooltip: 'Product',
    },
    {
        headerName: "Style Code",
        field: 'sku',
        sortable: true,
        headerTooltip: 'Style Code',
    },
    {
        headerName: "Gross Sales",
        field: 'grossSales',
        sortable: true,
        headerTooltip: 'Gross Sales',
    },
    {
        headerName: "Net Sales",
        field: 'netSales',
        sortable: true,
        headerTooltip: 'Net Sales',
    },
    {
        headerName: "Net Unit Sold",
        field: 'netUnitSold',
        sortable: true,
        headerTooltip: 'Net Unit Sold',
    },
    {
        headerName: "Discount",
        field: 'discount',
        sortable: true,
        headerTooltip: 'Discount',
    },
]
export const TopPerformingCategoryHeader = [
    {
        headerName: "Category",
        field: 'category',
        sortable: true,
        headerTooltip: 'Category',
    },
    {
        headerName: "Gross Sales",
        field: 'grossSales',
        sortable: true,
        headerTooltip: 'Gross Sales',
    },
    {
        headerName: "Net Sales",
        field: 'netSales',
        sortable: true,
        headerTooltip: 'Net Sales',
    },
    {
        headerName: "Net Unit Sold",
        field: 'netUnitSold',
        sortable: true,
        headerTooltip: 'Net Unit Sold',
    },
    {
        headerName: "Discount",
        field: 'discount',
        sortable: true,
        headerTooltip: 'Discount',
    },
]
export const BrandWiseSalesHeader = [
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        headerTooltip: 'Store Name',
    },
    {
        headerName: "Latest Invoice Date",
        field: 'invDate',
        headerTooltip: 'Latest Invoice Date',
        width: 120
    },
    {
        headerName: "Last Updated Date",
        field: 'lastUpdated',
        headerTooltip: 'Last Updated Date',
        width: 130
    },
    {
        headerName: "Net Unit Sold",
        field: 'netUnitSold',
        sortable: true,
        headerTooltip: 'Net Unit Sold',
        width: 100
    },
    {
        headerName: "Total MRP Value",
        field: 'mrpValue',
        sortable: true,
        headerTooltip: 'Total MRP Value',
        width: 180
    },
    {
        headerName: "Discount",
        field: 'discount',
        sortable: true,
        headerTooltip: 'Discount',
    },
    {
        headerName: "Gross Sales",
        field: 'grossSale',
        sortable: true,
        headerTooltip: 'Gross Sales',
    },
    {
        headerName: "Net Sales",
        field: 'netSale',
        sortable: true,
        headerTooltip: 'Net Sales',
    },
    // {
    //     headerName: "MD",
    //     field: 'markdown',
    //     sortable: true,
    //     headerTooltip: 'Markdown',
    //     width: 110,
    // },
    {
        headerName: "ASP",
        field: 'asp',
        sortable: true,
        headerTooltip: 'ASP',
        width: 120
    },
    {
        headerName: "Count of bill",
        field: 'cob',
        sortable: true,
        headerTooltip: 'Count of bill',
        width: 120
    },
    {
        headerName: "ATV",
        field: 'asv',
        sortable: true,
        headerTooltip: 'ATV',
        width: 120
    },
    {
        headerName: "BS",
        field: 'bs',
        sortable: true,
        headerTooltip: 'BS',
        width: 100
    },
    {
        headerName: "Sell Through",
        field: 'sellThrough',
        sortable: true,
        headerTooltip: 'Sell Through',
        width: 150
    },
    {
        headerName: "Return Unit",
        field: 'returnUnit',
        sortable: true,
        headerTooltip: 'Return Unit',
        width: 110
    },
    {
        headerName: "Return Value",
        field: 'returnValue',
        sortable: true,
        headerTooltip: 'Return Value',
    },
    // {
    //     headerName: "Sale Before Tax",
    //     field: 'saleBeforeTax',
    //     sortable: true,
    //     headerTooltip: 'Sale Before Tax',
    // },
    {
        headerName: "Total Tgt Value",
        field: 'totalTgtValue',
        sortable: true,
        headerTooltip: 'Total Tgt Value',
        width: 130,
    },
    {
        headerName: "Achievement Value",
        field: 'achievementValue',
        sortable: true,
        headerTooltip: 'Achievement Value',
        width: 130,
    },
    {
        headerName: "Achievement Percentage",
        field: 'achievementPercentage',
        sortable: true,
        headerTooltip: 'Achievement Percentage',
        width: 130,
    },
]
export const BrandWiseStoreSalesHeader = [
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        headerTooltip: 'Store Name',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 250
    },
    {
        headerName: "Latest Invoice Date",
        field: 'invDate',
        headerTooltip: 'Latest Invoice Date',
        width: 120
    },
    {
        headerName: "Last Updated Date",
        field: 'lastUpdated',
        headerTooltip: 'Last Updated Date',
        width: 130
    },
    {
        headerName: "Net Unit Sold",
        field: 'netUnitSold',
        sortable: true,
        headerTooltip: 'Net Unit Sold',
        width: 100
    },
    {
        headerName: "Total MRP Value",
        field: 'mrpValue',
        sortable: true,
        headerTooltip: 'Total MRP Value',
        width: 180
    },
    {
        headerName: "Discount",
        field: 'discount',
        sortable: true,
        headerTooltip: 'Discount',
    },
    {
        headerName: "Gross Sales",
        field: 'grossSale',
        sortable: true,
        headerTooltip: 'Gross Sales',
    },
    {
        headerName: "Net Sales",
        field: 'netSale',
        sortable: true,
        headerTooltip: 'Net Sales',
    },
    // {
    //     headerName: "MD",
    //     field: 'markdown',
    //     sortable: true,
    //     headerTooltip: 'Markdown',
    //     width: 110,
    // },
    {
        headerName: "ASP",
        field: 'asp',
        sortable: true,
        headerTooltip: 'ASP',
        width: 120
    },
    {
        headerName: "Count of bill",
        field: 'cob',
        sortable: true,
        headerTooltip: 'Count of bill',
        width: 120
    },
    {
        headerName: "ATV",
        field: 'asv',
        sortable: true,
        headerTooltip: 'ATV',
        width: 120
    },
    {
        headerName: "BS",
        field: 'bs',
        sortable: true,
        headerTooltip: 'BS',
        width: 100
    },
    {
        headerName: "Sell Through",
        field: 'sellThrough',
        sortable: true,
        headerTooltip: 'Sell Through',
        width: 150
    },
    {
        headerName: "Return Unit",
        field: 'returnUnit',
        sortable: true,
        headerTooltip: 'Return Unit',
        width: 110
    },
    {
        headerName: "Return Value",
        field: 'returnValue',
        sortable: true,
        headerTooltip: 'Return Value',
    },
    // {
    //     headerName: "Sale Before Tax",
    //     field: 'saleBeforeTax',
    //     sortable: true,
    //     headerTooltip: 'Sale Before Tax',
    // },
    {
        headerName: "Total Tgt Value",
        field: 'totalTgtValue',
        sortable: true,
        headerTooltip: 'Total Tgt Value',
        width: 130,
    },
    {
        headerName: "Achievement Value",
        field: 'achievementValue',
        sortable: true,
        headerTooltip: 'Achievement Value',
        width: 130,
    },
    {
        headerName: "Achievement Percentage",
        field: 'achievementPercentage',
        sortable: true,
        headerTooltip: 'Achievement Percentage',
        width: 130,
    },
]

export const stockDashboardTableHeader = [
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        headerTooltip: 'Store Name',
        width: 260,
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
        },
    },
    {
        headerName: "Category",
        field: 'category',
        sortable: true,
        headerTooltip: 'Category',
    },
    {
        headerName: "Style Code",
        field: 'styleCode',
        sortable: true,
        headerTooltip: 'Style Code',
    },
    {
        headerName: "Color",
        field: 'color',
        sortable: true,
        headerTooltip: 'Color',
    },
    {
        headerName: "Image",
        field: 'image',
        sortable: true,
        headerTooltip: 'Image',
    },
    {
        headerName: "MRP",
        field: 'mrp',
        sortable: true,
        headerTooltip: 'MRP',
    },
    {
        headerName: "Broken Size",
        field: 'brokenSize',
        sortable: true,
        headerTooltip: 'Broken Size',
    },
    {
        headerName: "Pivotal Size",
        field: 'pivotalSize',
        sortable: true,
        headerTooltip: 'Pivotal Size',
    },
    {
        headerName: "Current Stock",
        field: 'currentStock',
        sortable: true,
        headerTooltip: 'Current Stock',
    },
    {
        headerName: "Sold Out Count",
        field: 'soldOutCount',
        sortable: true,
        headerTooltip: 'Sold Out Count',
    },
]
export const TargetVsAchievementTableHeader = [
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        headerTooltip: 'Store Name',
        width: 200,
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Store Code",
        field: 'storeCode',
        sortable: true,
        headerTooltip: 'Store Code',
        width: 150,
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
            filterOptions: ["contains"],
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: "Month",
        field: 'month',
        sortable: true,
        filter: false,
        headerTooltip: 'Month',
    },
    {
        headerName: "Region",
        field: 'region',
        sortable: true,
        filter: false,
        headerTooltip: 'Region',
        width: 150,
    },
    {
        headerName: "State",
        field: 'state',
        sortable: true,
        filter: false,
        headerTooltip: 'State',
        width: 150,
    },
    {
        headerName: "Reportee Name",
        field: 'reporteeName',
        sortable: true,
        filter: false,
        headerTooltip: 'Reportee Name',
        width: 150,
    },
    {
        headerName: "FL Name",
        field: 'flName',
        sortable: true,
        filter: false,
        headerTooltip: 'FL Name',
        width: 150,
    },
    {
        headerName: "FL Status",
        field: 'flStatus',
        sortable: true,
        headerTooltip: 'FL Status',
        width: 150,
        filter: false,
    },
    {
        headerName: "Sell Thru",
        field: 'sellThru',
        sortable: true,
        headerTooltip: 'Sell Thru',
        width: 150,
        filter: false,
    },
    {
        headerName: "Total Tgt Value",
        field: 'totalTgtValue',
        sortable: true,
        headerTooltip: 'Total Tgt Value',
        width: 150,
        filter: false,
    },
    {
        headerName: "Total Tgt QTY",
        field: 'totalTgtQty',
        sortable: true,
        headerTooltip: 'Total Tgt QTY',
        width: 150,
        filter: false,
    },
    {
        headerName: "Achievement Value",
        field: 'achievementValue',
        sortable: true,
        headerTooltip: 'Achievement Value',
        width: 150,
        filter: false,
    },
    {
        headerName: "Achievement Percentage",
        field: 'achievementPercentage',
        sortable: true,
        headerTooltip: 'Achievement Percentage',
        width: 150,
        filter: false,
    },
    {
        headerName: "Action",
        field: 'targetAction',
        sortable: false,
        headerTooltip: 'Action',
        filter: false,
    },
]

export const IncetiveDashboardTableHeader = [
    {
        headerName: "Employee Name",
        field: 'employeeName',
        sortable: true,
        headerTooltip: 'Employee Name',
        width: 150,
    },
    {
        headerName: "Designation",
        field: 'designation',
        sortable: true,
        headerTooltip: 'Designation',
        width: 120,
    },
    {
        headerName: "Store Name",
        field: 'nameStore',
        sortable: true,
        headerTooltip: 'Store Name',
        width: 200,
    },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        sortable: true,
        headerTooltip: 'Brand Name',
        width: 120,
    },
    {
        headerName: "Month",
        field: 'month',
        sortable: true,
        headerTooltip: 'Month',
        width: 120,
    },
    {
        headerName: "No. of Bill",
        field: 'noOfBill',
        sortable: true,
        headerTooltip: 'No. of Bill',
        width: 120,
    },
    {
        headerName: "Quantity",
        field: 'quantity',
        sortable: true,
        headerTooltip: 'Quantity',
        width: 120,
    },
    {
        headerName: "MRP Value",
        field: 'MRPValue',
        sortable: true,
        headerTooltip: 'MRP Value',
        width: 120,
    },
    {
        headerName: "Target",
        field: 'target',
        sortable: true,
        headerTooltip: 'Target',
        width: 120,
    },
    {
        headerName: "Achievement %",
        field: 'achievementPerCent',
        sortable: true,
        headerTooltip: 'Achievement %',
        width: 150,
    },
    {
        headerName: "Monthly Incentive",
        field: 'monthlyIncentive',
        sortable: true,
        headerTooltip: 'Monthly Incentive',
        width: 180,
    },
    {
        headerName: "WOW Bill Incentive",
        field: 'WOWBillIncentive',
        sortable: true,
        headerTooltip: 'WOW Bill Incentive',
        width: 180,
    },
    {
        headerName: "Special Incentive",
        field: 'specialIncentive',
        sortable: true,
        headerTooltip: 'Special Incentive',
        width: 150,
    },
    {
        headerName: "Total Incentive",
        field: 'totalIncentive',
        sortable: true,
        headerTooltip: 'Total Incentive',
        width: 150,
    },
]

export const PromotionDashboardTableHeader = [
    {
        headerName: "Promotion Description",
        field: 'description',
        sortable: true,
        headerTooltip: 'Promotion Description',
    },
    {
        headerName: "Brand Name",
        field: 'brand_data',
        sortable: true,
        headerTooltip: 'Brand Name',
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
        },
        // width: 120
    },
    {
        headerName: "Region",
        field: 'region',
        sortable: true,
        headerTooltip: 'Region',
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
        },
        // width: 120
    },
    {
        headerName: "State",
        field: 'state',
        sortable: true,
        headerTooltip: 'State',
        filter: true,
        filterParams: {
            buttons: ["reset", "apply"],
        },
        // width: 120
    },
    {
        headerName: "Stores",
        field: 'store_detail',
        sortable: true,
        headerTooltip: 'Stores',
        // width : 270
    },
    {
        headerName: "Promotion Applied",
        field: 'promotion_type_value',
        sortable: true,
        headerTooltip: 'Promotion Applied',
        width: 200
    },
    {
        headerName: "Status",
        field: 'brandStatus',
        sortable: true,
        headerTooltip: 'Status',
        width: 100
    },
    {
        headerName: "Action",
        field: 'promotionAction',
        sortable: true,
        headerTooltip: 'Action',
        // width: 150
    },
]
export const ItemMasterHeader = [
    {
        headerName: "Barcode",
        field: 'barcode',
        headerTooltip: 'Barcode',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
        width: 180
    },
    {
        headerName: "Product Code",
        field: 'styleCode',
        headerTooltip: 'Product Code',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
        width: 150
    },
    {
        headerName: "Last Updated",
        field: 'lastUpdated',
        headerTooltip: 'Last Updated',
        // sortable: true,
        width: 150
    },
    {
        headerName: "Brand Name",
        field: 'nameBrand',
        headerTooltip: 'Brand Name',
        sortable: true,
        width: 150
    },
    {
        headerName: "Category Name",
        field: 'categoryDivision',
        headerTooltip: 'Category Name',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
        width: 120
    },
    {
        headerName: "FIT",
        field: 'categoryGroup',
        headerTooltip: 'FIT',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
        width: 120
    },
    {
        headerName: "Pattern",
        field: 'categorySection',
        headerTooltip: 'Pattern',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
        width: 180
    },
    {
        headerName: "Brand Code",
        field: 'brandCode',
        headerTooltip: 'Brand Code',
        sortable: true,
        width: 90
    },
    {
        headerName: "Sub Brand",
        field: 'subBrand',
        headerTooltip: 'Sub Brand',
        sortable: true,
        width: 130
    },
    {
        headerName: "Department",
        field: 'department',
        headerTooltip: 'Department',
        sortable: true,
        width: 120
    },
    {
        headerName: "Category Code",
        field: 'catCode',
        headerTooltip: 'Category Code',
        sortable: true,
        width: 100
    },
    {
        headerName: "Merchandise Hierarchy",
        field: 'merchandiseHierarchy',
        headerTooltip: 'Merchandise Hierarchy',
        sortable: true,
    },
    {
        headerName: "Product SKU Code",
        field: 'ProSKUCode',
        headerTooltip: 'Product SKU Code',
        sortable: true,
        width: 150
    },
    {
        headerName: "Product SKU Desc",
        field: 'ProdSKUDesc',
        headerTooltip: 'Product SKU Desc',
        sortable: true,
    },
    {
        headerName: "HSN Code",
        field: 'HSNCode',
        headerTooltip: 'HSN Code',
        sortable: true,
        width: 150

    },
    {
        headerName: "UOM",
        field: 'UOM',
        headerTooltip: 'UOM',
        sortable: true,
        width: 100
    },
    {
        headerName: "Colour",
        field: 'color',
        headerTooltip: 'Colour',
        sortable: true,
        width: 120
    },
    {
        headerName: "Size",
        field: 'size',
        headerTooltip: 'Size',
        sortable: true,
        width: 100
    },
    {
        headerName: "Season",
        field: 'season',
        headerTooltip: 'Season',
        sortable: true,
        width: 120
    },
    {
        headerName: "Product Type",
        field: 'productType',
        headerTooltip: 'Product Type',
        sortable: true,
        width: 120
    },
    {
        headerName: "MH1 Description",
        field: 'MH1Description',
        headerTooltip: 'MH1 Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "MH2 Description",
        field: 'MH2Description',
        headerTooltip: 'MH2 Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "MH3 Description",
        field: 'MH3Description',
        headerTooltip: 'MH3 Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "MH4 Description",
        field: 'MH4Description',
        headerTooltip: 'MH4 Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "MH5 Description",
        field: 'MH5Description',
        headerTooltip: 'MH5 Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "VARIANT - I",
        field: 'VARIANT1',
        headerTooltip: 'VARIANT - I',
        sortable: true,
        width: 120
    },
    {
        headerName: "VARIANT - II",
        field: 'VARIANT2',
        headerTooltip: 'VARIANT - II',
        sortable: true,
        width: 120
    },
    {
        headerName: "VARIANT - III",
        field: 'VARIANT3',
        headerTooltip: 'VARIANT - III',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - I",
        field: 'ATTRIBUTE1',
        headerTooltip: 'ATTRIBUTE - I',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - II",
        field: 'ATTRIBUTE2',
        headerTooltip: 'ATTRIBUTE - II',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - III",
        field: 'ATTRIBUTE3',
        headerTooltip: 'ATTRIBUTE - III',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - IV",
        field: 'ATTRIBUTE4',
        headerTooltip: 'ATTRIBUTE - IV',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - V",
        field: 'ATTRIBUTE5',
        headerTooltip: 'ATTRIBUTE - V',
        sortable: true,
        width: 120
    },
    {
        headerName: "Season Code",
        field: 'seasonCode',
        headerTooltip: 'Season Code',
        sortable: true,
        width: 120
    },
    {
        headerName: "Season Description",
        field: 'seasonDesc',
        headerTooltip: 'Season Description',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE - VI",
        field: 'ATTRIBUTE6',
        headerTooltip: 'ATTRIBUTE - VI',
        sortable: true,
        width: 120
    },
    {
        headerName: "ATTRIBUTE – VII",
        field: 'ATTRIBUTE7',
        headerTooltip: 'ATTRIBUTE – VII',
        sortable: true,
        width: 120
    },
    {
        headerName: "Cost",
        field: 'Cost',
        headerTooltip: 'Cost',
        sortable: true,
        width: 120
    },
    {
        headerName: "MRP",
        field: 'MRP',
        headerTooltip: 'MRP',
        sortable: true,
        width: 120
    },
    {
        headerName: "Discount",
        field: 'Discount',
        headerTooltip: 'Discount',
        sortable: true,
        width: 120
    },
    {
        headerName: "Offer Price",
        field: 'offerPrice',
        headerTooltip: 'Offer Price',
        sortable: true,
        width: 120
    },
];
export const ReconReportHeader = [
    {
        headerName: "Store Code",
        field: 'codeStore',
        headerTooltip: 'Store Code',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
    },
    {
        headerName: "Store Name",
        field: 'nameStore',
        headerTooltip: 'Store Name',
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ["clear", "apply"],
        },
    },
    {
        headerName: "Store Email Id",
        field: 'email',
        headerTooltip: 'Store Email Id',
        // sortable: true,
        // filter: true,
        // filterParams: {
        //     buttons: ["clear", "apply"],
        // },
    },
    {
        headerName: "Download File",
        field: 'reconFileDownload',
        headerTooltip: 'Download File',
    },
    {
        headerName: "YTD Reports",
        field: 'ytdReconReport',
        headerTooltip: 'YTD Reports',
    },

];
export const EmployeeStatusHeader = [
    {
        headerName: "Employee ID",
        field: 'emplId',
        headerTooltip: 'Employee ID',
        sortable: true,
        filter: true,
    },
    {
        headerName: "User Name",
        field: 'nameUser',
        headerTooltip: 'User Name',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Present Day",
        field: 'presentDay',
        headerTooltip: 'Present Day',
        sortable: false,
    },
    {
        headerName: "Absent Day",
        field: 'absentDay',
        headerTooltip: 'Absent Day',
        sortable: false,
    },
    {
        headerName: "Total Sales Count",
        field: 'totalSalesCount',
        headerTooltip: 'Total Sales Count',
        sortable: false,
    },
    {
        headerName: "Action",
        field: 'employeeStatusaction',
        headerTooltip: 'Action',
        sortable: false,
    },

];
export const EmployeeStatusSalesDetailsHeader = [

    {
        headerName: "Brand Name",
        field: 'nameBrand',
        headerTooltip: 'Brand Name',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Store Name",
        field: 'nameStore',
        headerTooltip: 'Store Name',
        sortable: true,
        filter: true,
    },
    // {
    //     headerName: "Status",
    //     field: 'employeeSalesStatus',
    //     headerTooltip: 'Status',
    //     sortable: true,
    //     filter: true,
    // },
    {
        headerName: "Barcode",
        field: 'barcode',
        headerTooltip: 'Barcode',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Invoice Date",
        field: 'invoiceDate',
        headerTooltip: 'Invoice Date',
        sortable: true,
        // filter: true,
    },
    {
        headerName: "Category",
        field: 'category',
        headerTooltip: 'Category',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Sub Category",
        field: 'subCategory',
        headerTooltip: 'Sub Category',
        sortable: true,
        filter: true,
    },

    // {
    //     headerName: "Product Type",
    //     field: 'productType',
    //     headerTooltip: 'Product Type',
    // },
    {
        headerName: "Color",
        field: 'color',
        headerTooltip: 'Color',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Size",
        field: 'size',
        headerTooltip: 'Size',
        sortable: true,
        filter: true,
    },
    {
        headerName: "Pack Of",
        field: 'packOf',
        headerTooltip: 'Pack Of',
        sortable: true,
        filter: true,
    },
    {
        headerName: "MRP",
        field: 'MRP',
        headerTooltip: 'MRP',
        sortable: true,
        filter: true,
    },

];
export const notificationTableHeader = [

    {
        headerName: "Notification Subject",
        field: 'notiSub',
        headerTooltip: 'Notification Subject',
        // sortable: true,
        // filter: true,
    },
    {
        headerName: "Notification Body",
        field: 'notiBody',
        headerTooltip: 'Notification Body',
        // sortable: true,
        // filter: true,
    },
    {
        headerName: "Date",
        field: 'date',
        headerTooltip: 'Date',
    },
    // {
    //     headerName: "Time",
    //     field: 'time',
    //     headerTooltip: 'Time',
    // },
    {
        headerName: "Action",
        field: 'notiAction',
        headerTooltip: 'Action',
    },

];
