import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../StoreManagement/storeManagement.css';
import '../../global.css';
import CustomPagination from '../../ReUsableComponent/Pagination';
import Loader from '../../ReUsableComponent/Loader';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter'
import { EmployeeStatusHeader } from '../TableHeaders';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { employeeStatusActions } from '../../store/reducers/EmployeeStatusReducer';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import { GetBrandNameList, GetEmployeeStatusList } from './EmployeeStatus.service';
import CustomMonthYearPicker from '../../ReUsableComponent/CustomMonthYearPicker';
import dayjs from 'dayjs';
import { RefreshOutlined } from '@mui/icons-material';


const EmployeeStatus = () => {
    const gridRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [loading, setLoading] = React.useState(false);
    const selectedPage = useSelector(state => state.brandManagement.selectedPage);
    const newSelectedBrandId = useSelector((state) => state.employeeStatus.selectedBrandId);
    const getSelectedCurrentDate = useSelector((state) => state.employeeStatus.selectedCurrentDate);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [brandList, setBrandList] = useState([]);
    const [dropSearchValue, setdropSearchValue] = React.useState('');
    const [employeeStatusTableData, setEmployeeStatusTableData] = useState([]);
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const [selectedMonthYear, setSelectedMonthYear] = useState(getSelectedCurrentDate);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    useEffect(() => {
        getAllBrand();
    }, []);

    useEffect(() => {
        if (selectedMonthYear) {
            getAllEmployeeStatusList();
        }
    }, [selectedMonthYear]);

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setLoading(false);
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
                setSelectedBrand({ label: newSelectedBrandId?.label ? newSelectedBrandId?.label : brands[0]?.label, value: newSelectedBrandId?.value ? newSelectedBrandId?.value : brands[0]?.value });
                getAllEmployeeStatusList(newSelectedBrandId?.value ? newSelectedBrandId?.value : brands[0]?.value);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };
    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
                getAllEmployeeStatusList(event.target.value);
                dispatch(employeeStatusActions.setSelectedBrandId({ type: { label: brand?.label, value: event.target.value } }));
            }
        });
    }


    const getCurrentYearAndMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    const getCurrentYearAndMonthWithData = (value) => {
        const currentDate = new Date(value);
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    const onDateSelect = (value) => {
        setSelectedMonthYear(value);
        dispatch(employeeStatusActions.setSelectedCurrentDate({ type: value }));
    }

    const storeTradeNameCheck = (store, tradeName) => {
        if (tradeName && tradeName !== 'null') {
            return tradeName
        } else {
            return store
        }
    }

    const getAllEmployeeStatusList = (selectedBrandId) => {
        setLoading(true);
        let param = `?brand=${selectedBrandId ? selectedBrandId : selectedBrand?.value}&month=${selectedMonthYear !== '' ? getCurrentYearAndMonthWithData(selectedMonthYear) : getCurrentYearAndMonth()}&page=${selectedPage}&limit=20`
        GetEmployeeStatusList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setLoading(false);
                let employeeStatus = []
                response.data.data.employeeRecords.forEach(element => {
                    let brandObject = {
                        id: element.userID,
                        emplId: element.emplId,
                        nameUser: element.user_name,
                        presentDay: element.total_present,
                        absentDay: element.total_absent,
                        totalSalesCount: element.total_sale_qty,
                        employeeStatusaction: true,
                        store_name: storeTradeNameCheck(element.store_name, element.store_trade_name),
                    }
                    employeeStatus.push(brandObject)
                });
                setEmployeeStatusTableData(employeeStatus);
                setTotalPagesCount(response?.data?.data?.totalRecords);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }





    // table
    const onClickEmployeStatusView = (data) => {
        dispatch(employeeStatusActions.setEmployeeStatuseRowId({ type: data }));
        history.push('/app/viewEmployeeStatusDetails');
    }



    const handlePageChange = (pageValue) => {
        dispatch(employeeStatusActions.setSelectedPage({ type: pageValue }));
    }

    const onGridReady = (params) => {
        params.api.setFilterModel(employeeStatusTableData);
    };

    const onClickReset = () => {
        const gridApi = gridRef.current.api;
        gridApi.setFilterModel(null); // Clears all the filters
        // gridApi.onFilterChanged(); 
    }

    // const onFilterChanged = (event) => {

    // };


    return (
        <div className='mainContainer'>
            <div className='notoSansFont containerDiv'>
                <div className='filterMainDiv'>

                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <div className='position-relative'>
                        <button onClick={onClickReset} className='me-2 reset_filters_button'>
                            <span className='me-1 reset_filters_text'>Reset Filters</span>
                            <RefreshOutlined className='RefreshOutlined' />
                        </button>
                    </div>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>

                    <div className='position-relative'>
                        <CustomMonthYearPicker disabled={false} value={selectedMonthYear} defaultValue={selectedMonthYear} onDateChange={(newValue) => onDateSelect(newValue)} maxDateRange={dayjs()} required customClass={'customeClass'} />
                    </div>
                </div>
            </div>
            <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter
                        refGrid={gridRef}
                        // onFilterChanged={onFilterChanged} 
                        // filterModel={filterModel} 
                        totalRow={() => { }}
                        onGridReady={onGridReady}
                        column={EmployeeStatusHeader}
                        tableRowData={employeeStatusTableData}
                        // filter={defaultColDef} 
                        // handleActiveToggle={changeBrandStatus} 
                        // onClickEdit={onClickBrandEdit} 
                        onClickView={onClickEmployeStatusView}
                        customeHeightClass={'tableCustomeHeightClass'} />

                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalPagesCount} />
                </div>
            </div>

            {loading && <Loader />}
        </div>
    )
}

export default EmployeeStatus;