import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import './Feedback.css';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../ReUsableComponent/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { brandManagementActions } from '../../store/reducers/BrandManagementReducer';
import { feedbackTableHeader } from '../TableHeaders';
import { useHistory } from 'react-router-dom';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { GetFeedbackList } from './Feedback.service';
import Loader from '../../ReUsableComponent/Loader';
import { feedbackActions } from '../../store/reducers/FeedbackReducer';
import { ToastErrorService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { RefreshOutlined } from '@mui/icons-material';
import moment from 'moment';

const Feedback = () => {

    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const history = useHistory();

    // const [selectedPage, setSelectedPage] = useState(useSelector(state => state.feedback.selectedPage));
    const selectedPage = useSelector(state => state.feedback.selectedPage);
    const [loading, setLoading] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]);
    const [totalFeedback, setTotalFeedback] = useState([]);
    const pageSize = 20;
    const filterModel = useSelector((state) => state.feedback.tableFilters);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);

    useEffect(() => {
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        dispatch(userAuthActions.setActivePage({ type: '/feedback' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Feedback' }));
        getAllFeedback(selectedPage);
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // editable: true,
            filter: true,
        };
    }, []);

    const onClickShareFeedback = () => {
        history.push('/app/shareFeedback');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    };

    const handlePageChange = (pageValue) => {
        // setSelectedPage(pageValue);
        dispatch(feedbackActions.setSelectedPage({ type: pageValue }));
        getAllFeedback(pageValue);
    };

    const onClickFeedbackView = (feedbackId) => {
        history.push('/app/viewFeedbackDetails');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        getAllFeedback(selectedPage, feedbackId);
    };

    const onFilterChanged = (event) => {
        dispatch(feedbackActions.setTableFilters({ type: event.api.getFilterModel() }));
    };

    const onGridReady = (params) => {
        params.api.setFilterModel(filterModel);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value
        } else {
            return '-'
        }
    }

    const convertDate = (date_string) => {
        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(date_string);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const getAllFeedback = (currentPage, feedbackId) => {
        setLoading(true);
        let params = `?page=${currentPage}&limit=${pageSize}`;
        if (feedbackId) {
            params = params + `&id=${feedbackId}`;
        }
        GetFeedbackList(params, USER_TOKEN).then(response => {
            let tableData = [];
            if (!response.data.data.totalFeedback) {
                dispatch(feedbackActions.setViewFeedbackDetails({ type: response.data.data }));
            } else {
                setTotalFeedback(response.data.data.totalFeedback);
                response.data.data.feedback.forEach(item => {
                    let rowData = {
                        employeeId: valueCheck(item?.userDetail?.emp_id),
                        name: valueCheck(item?.userDetail?.userName),
                        feedbackBrandName: valueCheck(item?.userDetail?.brandName),
                        feedbackStoreName: valueCheck((item?.storeDetail?.store_trade_name && item?.storeDetail?.store_trade_name !== 'null') ? item?.storeDetail?.store_trade_name : item?.storeDetail?.store_name),
                        area: valueCheck(item?.storeDetail?.store_area),
                        city: valueCheck(item?.storeDetail?.store_city),
                        title: valueCheck(item?.title),
                        feedbackStatus: valueCheck(item?.feedbackAction),
                        id: valueCheck(item?._id),
                        createdDate: convertDate(item?.created_at)
                    };
                    tableData.push(rowData);
                })
                setFeedbackList(tableData);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickReset = () => {
        const gridApi = gridRef.current;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-end align-items-center'>
                <button onClick={onClickReset} className='me-2 reset_filters_button'>
                    <span className='me-1 reset_filters_text'>Reset Filters</span>
                    <RefreshOutlined className='RefreshOutlined' />
                </button>
                <div>
                    <CustomBlueButton customCreatePropsBtnClass={'feedbackBtn'} onClick={onClickShareFeedback} disabled={false} title={'Share Feedback'} />
                </div>
            </div>
            <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter refGrid={gridRef} totalRow={() => { }} onFilterChanged={onFilterChanged} filterModel={filterModel} onGridReady={onGridReady} column={feedbackTableHeader} tableRowData={feedbackList} filter={defaultColDef} handleActiveToggle={null} onClickEdit={null} onClickView={onClickFeedbackView} customeHeightClass={'tableCustomeHeightClass'} />
                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={pageSize} currentPage={selectedPage} totalItems={totalFeedback} />
                </div>
            </div>

            {loading && <Loader />}

        </div>
    )
}

export default Feedback