import React, { Fragment } from "react";
import { useHistory } from 'react-router-dom';
import './login.css';
import InputTextField from "../../../ReUsableComponent/InputTextField";
import EyeSlashIcon from '../../../assets/svg/eyeSlash.svg';
import EyeOpenIcon from '../../../assets/svg/eyeOpen.svg';
import '../../../global.css';
import PrimaryButton from "../../../ReUsableComponent/PrimaryButton";
import { AdminLogin } from "../UserAuth.service";
import Loader from "../../../ReUsableComponent/Loader";
import { ToastContainer } from "react-toastify";
import { ToastErrorService, ToastSuccessService } from "../../../ReUsableComponent/ToastNotification/ToastService";
import CustomModal from "../../../ReUsableComponent/CustomModal";
import CloseIcon from '../../../assets/svg/crossIcon.svg';
import { useDispatch } from "react-redux";
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { decryptResponse, encryptRequest } from "../../../Configurations/Crypto";

const forceLogoutStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 380,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const Login = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [emailDetail, setEmailDetail] = React.useState({ value: '', valid: true, requiredName: true });
    const [password, setPassword] = React.useState({ value: '', valid: true, requiredName: true });
    const [showPwd, setShowPwd] = React.useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false);
    const [openForceLogoutModal, setOpenForceLogoutModal] = React.useState(false);
    const [errorResponse, setErrorResponse] = React.useState([]);
    const [loginRespone, setLoginRespone] = React.useState([]);

    const onInputEmail = (event) => {
        const REGEX = /^\S+@\S+\.\S+$/;
        if (event.target.value !== "" && REGEX.test(event.target.value)) {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: true

            })
        } else if (event.target.value === "") {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: false
            })
        }
        else {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: false,
                requiredName: true
            })
        }
    }

    const onInputPassword = (event, type) => {
        const REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if (event.target.value !== "" && REGEX.test(event.target.value)) {
            setPassword({
                ...password,
                value: event.target.value,
                valid: true,
                requiredName: true
            })
        } else if (event.target.value === "") {
            setPassword({
                ...password,
                value: event.target.value,
                valid: true,
                requiredName: false
            })
        }
        else {
            setPassword({
                ...password,
                value: event.target.value,
                valid: false,
                requiredName: true
            })
        }
    }

    React.useEffect(() => {
        if ((emailDetail?.value !== '' && emailDetail?.valid) && (password?.value !== '' && password?.valid)) {
            setSubmitBtnDisabled(false)
        } else {
            setSubmitBtnDisabled(true)
        }

    }, [emailDetail, password]);

    React.useEffect(() => {
        if (localStorage) {
            dispatch({ type: "USER_LOGOUT" });
        }
    }, [])

    const handleShowPwd = () => {
        setShowPwd(prev => !prev)
    }

    const onCLickForgotPwd = () => {
        history.push('/auth/forgotPassword')
    }

    const onClickSubmit = (forceLogout) => {
        localStorage.clear();
        setLoading(true);
        let requestBody = {
            "emailEmpId": emailDetail?.value,
            "password": password?.value,
            "loginType": "web",
            "forceLogout": forceLogout
        }
        AdminLogin(requestBody).then(response => {
            if (response?.data?.statusCode === 200) {
                dispatch(userAuthActions.setUserToken({ type: response?.data?.token?.access_token }));
                ToastSuccessService('Logged in successfully');
                setLoginRespone(response?.data?.data);
                history.push('/app/viewCardBrandManagement');
                dispatch(userAuthActions.setUserId({ type: response?.data?.data?.user_id }));
                dispatch(userAuthActions.setUserStatus({ type: response?.data?.data?.user_status }));
                dispatch(userAuthActions.setUserRole({ type: response?.data?.data?.role }));
                dispatch(userAuthActions.setUserEmail({ type: response?.data?.data?.email_id }));
                dispatch(userAuthActions.setUserName({ type: response?.data?.data?.name.split(" ")[0] }));
                dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
                dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
                dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
            }
        }).catch(error => {

            if (error?.response?.data?.statusCode === 400) {
                setErrorResponse(error?.response?.data);
                if (error?.response?.data?.message === 'A session already running in another device') {
                    setOpenForceLogoutModal(true);
                } else {
                    ToastErrorService(error?.response?.data.message);
                }
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickForceLogout = () => {
        setOpenForceLogoutModal(false);
        onClickSubmit(true);
    }

    return (
        <Fragment>
            <div className="loginForm customShadow">
                <div className="montSerratFont fontSize24">
                    Sign-in to your account
                </div>
                {/* <div className="notoSansFont paddingTop32 textColorGray">Sign-in to your account</div> */}
                <div className="paddingTop24">
                    <InputTextField label='E-Mail' type='email' helperText='' error={false} required={true} onChange={(event) => onInputEmail(event)} value={emailDetail?.value} disabled={false} />
                </div>
                <div className="pt-3 position-relative">
                    <InputTextField label='Enter Password' maxLength={64} type={showPwd ? 'text' : 'password'} helperText='' error={false} required={true} onChange={(event) => onInputPassword(event)} value={password?.value} disabled={false} />
                    <div className='position-absolute pwdViewBtn' onClick={handleShowPwd}>
                        <img src={showPwd ? EyeOpenIcon : EyeSlashIcon} alt='' />
                    </div>
                </div>
                <div className="textColorBlue fontMedium paddingTop18 cursorPointer widthFit" onClick={onCLickForgotPwd}>
                    Forgot Password?
                </div>
                <div className="paddingTop24">
                    <PrimaryButton disabled={submitBtnDisabled} title='Submit' onClick={() => onClickSubmit(false)} />
                </div>
                <div className="notoSansFont fontSize11 textColorGray paddingTop12">
                    By Continuing, You Agree With Our <a href="">Terms & Conditions</a>
                </div>
            </div>
            {loading && <Loader />}

            {/* Force Logout Confirmation Modal */}
            <CustomModal open={openForceLogoutModal} subClassess={'logout-modal'} handleClose={null} style={forceLogoutStyle} content={
                <div className="text-center">
                    <div className="fontSize18 fontBold">{errorResponse.message}</div>
                    <div className='forceLogoutCloseIconPlacement cursorPointer' onClick={() => setOpenForceLogoutModal(false)}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <div className="fontSize14 pt-2">Do you want to force logout other session?</div>
                    <div className="pt-3">
                        <PrimaryButton disabled={false} title='Force Logout' onClick={onClickForceLogout} />
                    </div>
                </div>
            } />

        </Fragment>)
};

export default Login;
