import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../StoreManagement/storeManagement.css';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import '../../global.css';
import CustomPagination from '../../ReUsableComponent/Pagination';
import Loader from '../../ReUsableComponent/Loader';
import ExportIcon from '../../assets/svg/downloadIcon.svg';
import ImportIcon from '../../assets/svg/uploadIcon.svg';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter'
import { brandTableHeader } from '../TableHeaders';
import { useHistory } from 'react-router-dom';
import { DownaloadBrandList, GetBrandList, UpdateBrandStatus, UploadBrandList } from './BrandManagement.service';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { useDispatch, useSelector } from "react-redux";
import SecondaryButton from '../../ReUsableComponent/SecondaryButton';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import CustomDropzone from '../../ReUsableComponent/CustomDropzone';
import SuccessModal from "../../ReUsableComponent/SuccesModal";
import Lottie from 'lottie-react';
import WellDoneIcon from "../../assets/lottie/welldone.json";
import { brandManagementActions } from '../../store/reducers/BrandManagementReducer';
import { DownloadTemplate } from '../../Configurations/Core.service';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import ProgressBarForFileUpload from '../../ReUsableComponent/ProgressBarForFileUpload';
import { saveAs } from 'file-saver';
import { RefreshOutlined } from '@mui/icons-material';


const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]

const BrandManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [loading, setLoading] = React.useState(false);
    const selectedPage = useSelector(state => state.brandManagement.selectedPage);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [BrandTableData, setBrandTableData] = useState([]);
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const [openFileUploadedModal, setOpenFileUploadedModal] = useState(false);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const userId = useSelector((state) => state.userAuthentication.userId);
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const openDownloadType = Boolean(anchorElForExport);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [progress, setProgress] = useState(10);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const filterModel = useSelector((state) => state.brandManagement.tableFilters);
    const USER_ID = useSelector(state => state.userAuthentication.userId);

    const defaultColDef = useMemo(() => {
        return {
            filter: true,
        };
    }, []);

    useEffect(() => {
        getAllBrandList();
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
    }, [selectedPage, filterModel])

    const handlePageChange = (pageValue) => {
        dispatch(brandManagementActions.setSelectedPage({ type: pageValue }));
    }
    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value;
        } else {
            return '-'
        }
    }

    const getAllBrandList = () => {
        setLoading(true);
        let reqBody = {
            "brand_name": filterModel?.brandName ? filterModel?.brandName?.filter : '',
            "brand_mobile": filterModel?.brandPhoneNo ? filterModel?.brandPhoneNo?.filter : '',
            "gstin": filterModel?.gstIn ? filterModel?.gstIn?.filter : '',
            "pan": filterModel?.pan ? filterModel?.pan?.filter : '',
            "email_id": filterModel?.emailId ? filterModel?.emailId?.filter : '',
            "brand_deck": filterModel?.brandDeck ? filterModel?.brandDeck?.filter : '',
            "product_type": filterModel?.productType ? filterModel?.productType?.filter : '',
            "business_model": filterModel?.businessModel ? filterModel?.businessModel?.filter : '',
            "company_contact_detail_person_name": filterModel?.productPersonName ? filterModel?.productPersonName?.filter : '',
            "company_contact_detail_mobile": filterModel?.productPersonPhoneNo ? filterModel?.productPersonPhoneNo?.filter : '',
            "company_contact_detail_person_dept": filterModel?.productPersonDepartment ? filterModel?.productPersonDepartment?.filter : '',
            "company_contact_detail_email": filterModel?.productPersonEmailId ? filterModel?.productPersonEmailId?.filter : '',
            "brand_status": filterModel?.brandStatus ? filterModel?.brandStatus?.filter : ''
        }
        let queryParams = `?page=${selectedPage}&limit=${20}&user_id=${USER_ID}`
        GetBrandList(queryParams, reqBody, USER_TOKEN).then(response => {
            let tableData = [];
            dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
            dispatch(brandManagementActions.setBrandCreatedBy({ type: response?.data?.data[0]?.created_by }));
            response?.data?.data?.map(item => {
                let row = {
                    brandName: valueCheck(item?.brand_name),
                    brandStatus: item?.status ? 'Active' : 'Inactive',
                    brandDeck: valueCheck(item?.brand_deck),
                    emailId: valueCheck(item?.email_id),
                    gstIn: valueCheck(item?.gstin),
                    pan: valueCheck(item?.pan),
                    productType: valueCheck(item?.product_type[0]),
                    brandPhoneNo: `+91 ${item?.brand_mobile}`,
                    businessModel: valueCheck(item?.business_model[0]),
                    productPersonName: valueCheck(item?.company_contact_detail?.person_name),
                    productPersonPhoneNo: item?.company_contact_detail?.mobile ? `+91 ${item?.company_contact_detail?.mobile}` : '-',
                    productPersonDepartment: valueCheck(item?.company_contact_detail?.person_dept),
                    productPersonEmailId: valueCheck(item?.company_contact_detail?.email),
                    id: item?._id,
                    status: item?.status,
                    is_profile_completed: item?.is_profile_completed,
                }
                tableData.push(row)
            })
            setBrandTableData(tableData);
            setTotalPagesCount(response?.data?.count);
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickCreateBrand = () => {
        history.push('/app/createBrand');
        dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
        dispatch(brandManagementActions.setStepProgress({ type: 0 }));
        dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {} }));
        dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return dateString
    }


    const downloadBrandList = (reqBody) => {
        setLoading(true);
        DownaloadBrandList(reqBody, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickExport = (event) => {
        setAnchorElForExport(event.currentTarget);
    }

    const downloadBrandTemplate = () => {
        setLoading(true);
        let param = `?template_type=brand_template`
        DownloadTemplate(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.location.href = response.data.data
                ToastSuccessService('Template Downloaded Successfully.')
            } else {
                ToastErrorService(response.data.message);
            }
            setOpenUploadFileModal(false);
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickBrandEdit = (brand_id) => {
        dispatch(brandManagementActions.setSelectedTab({ type: 0 }));
        dispatch(brandManagementActions.setViewBrandSelectedTabLabel({ type: 'Brand Owner Details' }));
        dispatch(brandManagementActions.setIsEditFormVisible({ type: false }));
        getBrandDetailById(brand_id, 'Edit');
    }

    const getBrandDetailById = (brand_id, action) => {
        setLoading(true);
        let param = `?brand_id=${brand_id}`
        GetBrandList(param, '', USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                dispatch(brandManagementActions.setViewBrandDetails({ type: response?.data?.data[0] }));
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            if (action.toUpperCase() === 'view'.toUpperCase()) {
                history.push('/app/viewBrandDetails');
            } else {
                history.push('/app/editBrandDetails');
            }
        })
    }

    const onClickBrandView = (brand_id) => {
        dispatch(brandManagementActions.setSelectedTab({ type: 0 }));
        dispatch(brandManagementActions.setViewBrandSelectedTabLabel({ type: 'Brand Owner Details' }));
        dispatch(brandManagementActions.setIsEditFormVisible({ type: true }));
        getBrandDetailById(brand_id, 'view');
    }


    const onClickImport = () => {
        setOpenUploadFileModal(true);
    }

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenUploadFileModal(false);
        }
    };

    const onClickOk = () => {
        setOpenFileUploadedModal(false);
        setOpenFileSelectedModal(false);
        getAllBrandList();
    }

    const onClickUploadBrand = () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("created_by", userId);
        UploadBrandList(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message)
            } else {
                setOpenFileUploadedModal(true);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const brandStatusFilterCheck = (value) => {
        if (value.charAt(0)?.toLowerCase() === 'a') {
            return true
        } else if (value.charAt(0)?.toLowerCase() === 'i') {
            return false
        } else {
            return null
        }
    }

    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            downloadBrandTemplate();
        } else {
            let requestBody = {
                "brand_name": filterModel?.brandName ? filterModel?.brandName?.filter : '',
                "product_type": filterModel?.productType ? filterModel?.productType?.filter : '',
                "business_model": filterModel?.businessModel ? filterModel?.businessModel?.filter : '',
                "status": filterModel?.brandStatus ? brandStatusFilterCheck(filterModel?.brandStatus?.filter) : null,
                "user_id": USER_ID
            }
            downloadBrandList(requestBody);
        }
        setAnchorElForExport(null);
    }
    const onClickDownloadTemplate = (event) => {
        downloadBrandTemplate();
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onGridReady = (params) => {
        params.api.setFilterModel(filterModel);
    };

    const onFilterChanged = (event) => {
        dispatch(brandManagementActions.setTableFilters({ type: event.api.getFilterModel() }));
    };

    const changeBrandStatus = (event) => {
        setLoading(true);
        let updatedStatus = !event.status;
        let param = `?brand_id=${event.id}&status=${updatedStatus}`;

        UpdateBrandStatus(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                if (updatedStatus) {
                    ToastSuccessService('Brand is Active');
                } else {
                    ToastSuccessService('Brand is Inactive');
                }

                getAllBrandList();
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickReset = () => {
        const gridApi = gridRef.current.api;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
    }

    return (
        <div className='mainContainer'>
            <div className='notoSansFont containerDiv'>
                <div className='filterMainDiv'>
                    {/* <div className='filterDiv cursorPointer me-3'>
                        <CalendarMonthIcon fontSize={'small'} style={{ color: '#4169E1' }} />
                        <span className='ps-2'>Select Date</span>
                    </div>
                    <div className='filterDiv fontSize14 cursorPointer'>
                        <KeyboardArrowDownRoundedIcon fontSize={'small'} />
                        <span className='ps-2'>Filter</span>
                    </div> */}
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <button onClick={onClickReset} className='me-2 reset_filters_button'>
                        <span className='me-1 reset_filters_text'>Reset Filters</span>
                        <RefreshOutlined className='RefreshOutlined' style={{}} />
                    </button>
                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                        <label className="cursorPointer">
                            <img src={ImportIcon} alt="" />
                        </label>
                    </div>
                    <div className='me-3 importExportFile' title='Download' onClick={onClickExport}>
                        <img src={ExportIcon} alt="" />
                    </div>
                    <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                        onChange={onSelectDownloadCategory} category={'Category'} />
                    <div>
                        <CustomBlueButton title={<div className='d-flex align-items-center'>
                            <span className='fontSize18 fontBold pr-13px'>+</span>
                            <div>Create Brand</div>
                        </div>} onClick={onClickCreateBrand} />
                    </div>
                </div>
            </div>
            <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter refGrid={gridRef} totalRow={() => { }} onFilterChanged={onFilterChanged} filterModel={filterModel} onGridReady={onGridReady} column={brandTableHeader} tableRowData={BrandTableData} filter={defaultColDef} handleActiveToggle={changeBrandStatus} onClickEdit={onClickBrandEdit} onClickView={onClickBrandView} customeHeightClass={'tableCustomeHeightClass'} />

                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalPagesCount} />
                </div>
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openUploadFileModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUploadFileModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={onClickUploadBrand} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />


            {/* File Uploaded Successfully Modal */}
            <SuccessModal style={SuccessUploadModalStyle} open={openFileUploadedModal} handleClose={null} content={<div className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Lottie className="welldoneLottie" animationData={WellDoneIcon} loop={false} />
                </div>
                <div className='fontBold textBlack fontSize18 paddingTop24'>File Uploaded Successfully!!!</div>
                <div className='fontSize12 pt-2'>We will be processing the data in background, you can continue with your work.</div>
                <div className='pt-4 d-flex justify-content-center align-items-center'>
                    <button className='modalButton'
                        onClick={onClickOk}>OK</button>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default BrandManagement