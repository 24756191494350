import React, { useRef, useState } from 'react';
import CustomHorizontalStepper from '../../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import CustomBlueButton from '../../../../ReUsableComponent/CustomBlueButton';
import '../../TeamManagement.css';
import ProgressBarWithLabel from '../../../../ReUsableComponent/ProgressBarWithLabel';
import InputTextField from '../../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../../ReUsableComponent/CustomAutocomplete';
import CustomDatePicker from '../../../../ReUsableComponent/DatePickerComponent';
import CustomDropzone from '../../../../ReUsableComponent/CustomDropzone';
import ImportIcon from '../../../../assets/svg/uploadIconForDoc.svg';
import SecondaryButton from '../../../../ReUsableComponent/SecondaryButton';
import MultiSelectCheckBox from '../../../../ReUsableComponent/MultiSelectCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBarForFileUpload from '../../../../ReUsableComponent/ProgressBarForFileUpload';
import PdfIcon from '../../../../assets/svg/pdf.svg';
import JPEG from '../../../../assets/svg/jpeg.svg';
import PNG from '../../../../assets/svg/png.svg';
import { userManagementActions } from '../../../../store/reducers/UserManagementReducer';
import dayjs from 'dayjs';
import { dateIntoDayJs } from '../../../../utils/DateConversions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const steps = ['Personal Information', 'Bank Details', 'Onboarding Details', 'Work Experience', 'Upload Documents'];
const GENDER = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }];
const STATUS = [{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }];
const CHANNELS = [{ label: 'MBO', value: 'MBO' }, { label: 'Regional Player', value: 'Regional Player' },
{ label: 'Departmental stores', value: 'Departmental stores' }, { label: 'EBO', value: 'EBO' },
{ label: 'Ecom', value: 'Ecom' }, { label: 'Others', value: 'Others' }];
const REGION_LIST = [{ label: 'North', value: 'North', name: 'region' }, { label: 'East', value: 'East', name: 'region' }, { label: 'West', value: 'West', name: 'region' }, { label: 'South', value: 'South', name: 'region' }];

const CreateUserForm = ({ activeStep, onInputValue, onChangeValue, onDateSelect, values, onSelectValue, handleFileDrop, validations, selectedRegion, selectedBrandState }) => {

    const dispatch = useDispatch();
    const STORE_LIST = useSelector(state => state.userManagement.storeList);
    const BRNAD_LIST = useSelector(state => state.userManagement.brandList);
    const STATES_LIST = useSelector(state => state.userManagement.statesList);
    const ROLE_LIST = useSelector(state => state.userManagement.userRoles);
    const DESIGNATION_LIST = useSelector(state => state.userManagement.designations);
    const USER_LIST = useSelector(state => state.userManagement.reportingMngr);
    const CHANNEL_LIST = useSelector(state => state.userManagement.channels);
    const SELECTED_STORES = useSelector(state => state.userManagement.selectedStores);
    const SELECTED_BRANDS = useSelector(state => state.userManagement.selectedBrands);
    // const SELECTED_STATES = useSelector(state => state.userManagement.selectedStates);
    const PAN_DOC = useSelector(state => state.userManagement.pan);
    const AADHAAR_DOC = useSelector(state => state.userManagement.aadhaar);
    const LOGIN_TYPE = [{ label: 'Web', value: 'web' }, { label: 'Mobile', value: 'mobile' }, { label: 'Both', value: 'both' }];
    const createUserRole = useSelector(state => state.userManagement.createUserRole);

    const fileTypeIcon = (fileType) => {
        if (fileType === 'pdf') {
            return PdfIcon
        } else if (fileType === 'jpeg' || fileType === 'jpg') {
            return JPEG
        } else {
            return PNG
        }
    }

    const removeFile = (file) => {
        if (file === 'adhar_doc') {
            dispatch(userManagementActions.setAadhaar({ type: {} }));
        } else {
            dispatch(userManagementActions.setPan({ type: {} }));
        }
    }

    if (activeStep === 0) {
        return <div>
            <div className='montSerratFont fontSemiBold fontSize18 textColorBlack'>
                User Personal Information
            </div>
            <form >
                <div className='pt-3 formContainer teamMngmnt customScroll'>
                    <div>
                        <InputTextField userTeam={'userTeam'} tranformText='capitalize' errored={values.name ? (validations?.name !== '' ? true : false) : false} errorMsg={validations.name} minLength={3} maxLength={80} name={'name'} type='text' disabled={false} value={values.name} onChange={(e) => onInputValue(e, 'Func_Alpha')} required label='Name' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.emp_id ? (validations?.emp_id !== '' ? true : false) : false} errorMsg={validations.emp_id} minLength={1} maxLength={10} name={'emp_id'} type='text' disabled={false} value={values.emp_id} onChange={onInputValue} required label='Employee Id' />
                    </div>
                    <div>
                        <CustomAutoComplete userTeam={'userTeam'} name={'gender'} errored={values.gender} value={values.gender} errorMsg={validations.gender} onChange={(e, value) => onChangeValue(e, 'gender', value)} required options={GENDER} label='Gender*' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.mobile_number ? (validations?.mobile_number !== '' ? true : false) : false} errorMsg={validations.mobile_number} minLength={10} maxLength={10} name={'mobile_number'} type='text' disabled={false} value={values.mobile_number} onChange={onInputValue} required label='Mobile Number' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.email_id ? (validations?.email_id !== '' ? true : false) : false} errorMsg={validations.email_id} minLength={6} maxLength={80} name={'email_id'} type='text' disabled={false} value={values.email_id} onChange={onInputValue} required label='E- Mail Id' />
                    </div>
                    <div>
                        <CustomAutoComplete userTeam={'userTeam'} name={'designation_id'} errored={!!values?.designation_id} value={values?.designation_id} errorMsg={validations.designation_id} onChange={(e, value) => onChangeValue(e, 'designation_id', value)} required options={DESIGNATION_LIST} label='User Designation*' />
                    </div>
                    <div>
                        <CustomAutoComplete userTeam={'userTeam'} name={'role'} errored={!!values?.role} value={values?.role} errorMsg={validations.role} onChange={(e, value) => onChangeValue(e, 'role', value)} required options={ROLE_LIST} label='User Role*' />
                    </div>
                    <div>
                        <CustomDatePicker userTeam={'userTeam'} name={'dob'} maxDateRange={dayjs().subtract(18, 'year')} disabled={false} errored={!!values?.dob} value={values.dob} errorMsg={validations.dob} onDateChange={(newValue) => onDateSelect(newValue, 'dob')} required label='Birth Date*' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} InputLabelProps={{ shrink: values.age ? true : false }} name={'age'} type='text' disabled={true} value={values.age} onChange={onInputValue} required label='Age' />
                    </div>
                    <div>
                        <CustomDatePicker userTeam={'userTeam'} name={'doj'} id={'doj'} disabled={!values?.dob} minDateRange={dayjs(dateIntoDayJs(values?.dob)).add(18, 'year')} errored={!!values?.doj} value={values.doj} errorMsg={validations.doj} onDateChange={(newValue) => onDateSelect(newValue, 'doj')} required label='Date Of Joining*' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.company ? (validations?.company !== '' ? true : false) : false} errorMsg={validations.company} minLength={3} maxLength={80} name={'company'} type='text' disabled={false} value={values.company} onChange={onInputValue} required label='Company' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.last_city ? (validations?.last_city !== '' ? true : false) : false} errorMsg={validations.last_city} minLength={3} maxLength={40} name={'last_city'} type='text' disabled={false} value={values.last_city} onChange={onInputValue} required={false} label='Last Working City/District' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.adhar_no ? (validations?.adhar_no !== '' ? true : false) : false} errorMsg={validations.adhar_no} minLength={12} maxLength={12} name={'adhar_no'} type='text' disabled={false} value={values.adhar_no} onChange={onInputValue} required label='Aadhaar Number' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.pan_no ? (validations?.pan_no !== '' ? true : false) : false} errorMsg={validations.pan_no} minLength={10} maxLength={10} name={'pan_no'} type='text' disabled={false} value={values.pan_no} onChange={onInputValue} required label='PAN Number' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.address ? (validations?.address !== '' ? true : false) : false} errorMsg={validations.address} minLength={3} maxLength={100} name={'address'} type='text' disabled={false} value={values.address} onChange={onInputValue} required label='Address' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.pincode ? (validations?.pincode !== '' ? true : false) : false} errorMsg={validations.pincode} minLength={6} maxLength={6} name={'pincode'} type='text' disabled={false} value={values.pincode} onChange={onInputValue} required label='Pincode' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} InputLabelProps={{ shrink: (values?.city || values?.city === '') ? true : false }} minLength={3} maxLength={40} value={values.city} required label='City' onChange={onInputValue} name={'city'} />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} disabled={true} InputLabelProps={{ shrink: (values?.region || values?.region === '') ? true : false }} minLength={3} maxLength={30} value={values.region} required label='Region' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} disabled={true} InputLabelProps={{ shrink: (values?.state || values?.state === '') ? true : false }} minLength={3} maxLength={20} value={values.state} required label='State' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} minLength={1} maxLength={4} name='door_count' type='text' disabled={false} value={values.door_count} onChange={onInputValue} required={false} label='Door Count' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.year_exp ? (validations?.year_exp !== '' ? true : false) : false} errorMsg={validations.year_exp} minLength={1} maxLength={4} name='year_exp' type='text' disabled={false} value={values.year_exp} onChange={onInputValue} required label='Years of Experience' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.gross_sales ? (validations?.gross_sales !== '' ? true : false) : false} errorMsg={validations.gross_sales} name='gross_sales' type='text' disabled={false} value={values.gross_sales} onChange={onInputValue} required={false} label='Annual Business Value (Gross Sales)' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.last_gross_ctc ? (validations?.last_gross_ctc !== '' ? true : false) : false} errorMsg={validations.last_gross_ctc} name='last_gross_ctc' type='text' disabled={false} value={values.last_gross_ctc} onChange={onInputValue} required label='Last Gross CTC (LPA)' />
                    </div>
                    <div>
                        <InputTextField userTeam={'userTeam'} errored={values.current_gross_ctc ? (validations?.current_gross_ctc !== '' ? true : false) : false} errorMsg={validations.current_gross_ctc} name='current_gross_ctc' type='text' disabled={false} value={values.current_gross_ctc} onChange={onInputValue} required label='Current Gross CTC (LPA)' />
                    </div>
                </div>
            </form>
        </div>
    } else if (activeStep === 1) {
        return <div>
            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                User Bank Details
            </div>
            <div className='pt-3 formContainer teamMngmnt customScroll'>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.bank_name ? (validations?.bank_name !== '' ? true : false) : false} errorMsg={validations.bank_name} name={'bank_name'} minLength={2} maxLength={120} type='text' disabled={false} value={values.bank_name} onChange={onInputValue} required label='Bank Name' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.accountNumber ? (validations?.accountNumber !== '' ? true : false) : false} errorMsg={validations.accountNumber} name={'accountNumber'} minLength={8} maxLength={20} type='text' disabled={false} value={values.accountNumber} onChange={onInputValue} required label='Account Number' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.bank_ifsc ? (validations?.bank_ifsc !== '' ? true : false) : false} errorMsg={validations.bank_ifsc} name={'bank_ifsc'} minLength={11} maxLength={11} type='text' disabled={false} value={values.bank_ifsc} onChange={onInputValue} required label='IFSC Code' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.branch_name ? (validations?.branch_name !== '' ? true : false) : false} errorMsg={validations.branch_name} name={'branch_name'} minLength={2} maxLength={80} type='text' disabled={false} value={values.branch_name} onChange={onInputValue} required label='Branch' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.branch_address ? (validations?.branch_address !== '' ? true : false) : false} errorMsg={validations.branch_address} name={'branch_address'} minLength={3} maxLength={100} type='text' disabled={false} value={values.branch_address} onChange={onInputValue} required label='Branch Address' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.esi_no ? (validations?.esi_no !== '' ? true : false) : false} errorMsg={validations.esi_no} name={'esi_no'} minLength={17} maxLength={17} type='text' disabled={false} value={values.esi_no} onChange={onInputValue} required label='ESI Number' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.pf_no ? (validations?.pf_no !== '' ? true : false) : false} errorMsg={validations.pf_no} name={'pf_no'} minLength={22} maxLength={26} type='text' disabled={false} value={values.pf_no} onChange={onInputValue} required label='PF Number' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.remarks ? (validations?.remarks !== '' ? true : false) : false} errorMsg={validations.remarks} name={'remarks'} minLength={2} maxLength={100} type='text' disabled={false} value={values.remarks} onChange={onInputValue} required label='Remarks' />
                </div>
            </div>
        </div>
    } else if (activeStep === 2) {
        return <div>
            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                User Onboarding Details
            </div>
            <div className='pt-3 formContainer teamMngmnt customScroll'>
                {/* <div>
                    <InputTextField userTeam={'userTeam'} name={'store_code'} key={'store_code'} minLength={1} maxLength={40} type='text' disabled={false} value={values?.store_detail?.store_code} onChange={onInputValue} required label='Store Code' />
                </div> */}
                <div>
                    <MultiSelectCheckBox userTeam={'userTeam'} errored={values?.brand_name?.length !== 0} errorMsg={validations.brand_name} selectedValue={values?.brand_name?.map(brand => brand?.brand_name)} handleChange={(e) => onSelectValue(e, 'brand_name')} required options={BRNAD_LIST} label='Brand Name*' />
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'brand_region'} errored={!!values?.brand_region} value={values?.brand_region} errorMsg={validations.brand_region} onChange={(e, value) => onChangeValue(e, 'brand_region', value)} required options={REGION_LIST} label='Brand Region' />
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'brand_state'} errored={!!values?.brand_state} value={values?.brand_state} errorMsg={validations.brand_state} onChange={(e, value) => onChangeValue(e, 'brand_state', value)} required options={STATES_LIST} label='Brand State' />
                </div>
                <div>
                    <MultiSelectCheckBox userTeam={'userTeam'} errored={values?.store_detail?.length !== 0} errorMsg={validations.store_detail} selectedValue={values?.store_detail?.map(store => store?.store_name)} handleChange={(e) => onSelectValue(e, 'store_id')} required options={STORE_LIST} label='Store Name*' />
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'reporting_manager'} errored={!!values?.reporting_manager} value={values?.reporting_manager} errorMsg={validations.reporting_manager} onChange={(e, value) => onChangeValue(e, 'reporting_manager', value)} required options={USER_LIST} label='Reporting Manager*' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} key={'reporting_manager_mob'} InputLabelProps={{ shrink: values.reporting_manager_mob ? true : false }} disabled name={'reporting_manager_mob'} minLength={3} maxLength={80} type='text' value={values?.reporting_manager_mob} onChange={onInputValue} required label='Reporting Manager Mobile Number' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} key={'reporting_manager_email'} InputLabelProps={{ shrink: values.reporting_manager_email ? true : false }} disabled name={'reporting_manager_email'} minLength={3} maxLength={80} type='text' value={values?.reporting_manager_email} onChange={onInputValue} required label='Reporting Manager Email Id' />
                </div>
                <div>
                    <CustomDatePicker userTeam={'userTeam'} disabled={false} errored={!!values?.value} value={values?.value} errorMsg={validations.value} onDateChange={(newValue) => onDateSelect(newValue, 'exit_date')} required label='Current Company Date Of Exit*' />
                    {/* <CustomDatePicker userTeam={'userTeam'} name={'exit_date'} id={'exit_date'} errored={!!values?.value} value={values.value} errorMsg={validations.value} onDateChange={(newValue) => onDateSelect(newValue, 'exit_date')} required label='Current Company Date Of Exit*' /> */}
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'login_type'} errored={!!values?.login_type} value={values?.login_type} errorMsg={validations.login_type} onChange={(e, value) => onChangeValue(e, 'login_type', value)} required options={LOGIN_TYPE} label='Login Type*' />
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'channel'} errored={!!values?.channel} value={values?.channel} errorMsg={validations.channel} onChange={(e, value) => onChangeValue(e, 'channel', value)} required options={CHANNEL_LIST} label='Channel*' />
                </div>
                <div>
                    <CustomAutoComplete userTeam={'userTeam'} name={'user_status'} disabled={true} errored={!!values?.user_status} value={values?.user_status} errorMsg={validations.user_status} onChange={(e, value) => onChangeValue(e, 'user_status', value)} required options={STATUS} label='User Status*' />
                </div>
            </div>
        </div>
    } else if (activeStep === 3) {
        return <div>
            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                User Work Experience
            </div>
            <div className='pt-3 formContainer teamMngmnt customScroll'>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.education ? (validations?.education !== '' ? true : false) : false} errorMsg={validations.education} key={'education'} name={'education'} minLength={2} maxLength={40} type='text' disabled={false} value={values.education} onChange={onInputValue} required label='User’s Education' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} key={'past_experience'} errored={values.past_experience ? (validations?.past_experience !== '' ? true : false) : false} errorMsg={validations.past_experience} name={'past_experience'} minLength={5} maxLength={40} type='text' disabled={false} value={values.past_experience} onChange={onInputValue} required={false} label='Past Experience' />
                </div>
                <div>
                    <InputTextField userTeam={'userTeam'} errored={values.previous_companies ? (validations?.previous_companies !== '' ? true : false) : false} errorMsg={validations.previous_companies} key={'previous_companies'} name={'previous_companies'} minLength={3} maxLength={200} type='text' disabled={false} value={values.previous_companies} onChange={onInputValue} required={false} label='Previous Companies' />
                </div>
            </div>
        </div>
    } else if (activeStep === 4) {
        return <div>
            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                Upload Documents
            </div>
            <div className='documentUpload teamMngmnt customScroll'>
                <div className='uploadCard'>
                    {PAN_DOC?.pan_doc?.fileUrl === undefined ? <CustomDropzone filesAccepted={['.pdf', '.jpg', '.jpeg']} onFileDrop={(files) => handleFileDrop(files, 'pan_doc')} displayContent={<div>
                        <div className='d-flex align-items-center'>
                            <button className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                <div className="cursorPointer">
                                    <img src={ImportIcon} alt="" />
                                </div>
                            </button>
                            <div>
                                <div className='fontMedium textColorBlack fontSize16'>Upload PAN</div>
                                <div className='fontSize14 textColorGray'>
                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                    <div className='pt-2'>PDF,JPEG or JPG</div>
                                </div>
                            </div>
                        </div>
                    </div>} />
                        : <div style={{ width: '100%' }}>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={fileTypeIcon(PAN_DOC?.pan_doc?.fileType)} alt="" />
                                    </label>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div className='fontMedium textColorBlack fontSize16'>Pan</div>
                                    <div className='fontSize14 textColorGray'>
                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('pan_doc')} progress={100} File={PAN_DOC.pan_doc} uploadSpeed={''} notShowBrand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='uploadCard'>
                    {AADHAAR_DOC?.adhar_doc?.fileUrl === undefined ? <CustomDropzone filesAccepted={['.pdf', '.jpg', '.jpeg']} onFileDrop={(files) => handleFileDrop(files, 'adhar_doc')} displayContent={<div>
                        <div className='d-flex align-items-center'>
                            <button className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                <div className="cursorPointer">
                                    <img src={ImportIcon} alt="" />
                                </div>
                            </button>
                            <div>
                                <div className='fontMedium textColorBlack fontSize16'>Upload Aadhaar</div>
                                <div className='fontSize14 textColorGray'>
                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                    <div className='pt-2'>PDF,JPEG or JPG</div>
                                </div>
                            </div>
                        </div>
                    </div>} />
                        :
                        <div style={{ width: '100%' }}>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={fileTypeIcon(AADHAAR_DOC?.adhar_doc?.fileType)} alt="" />
                                    </label>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div className='fontMedium textColorBlack fontSize16'>Aadhaar</div>
                                    <div className='fontSize14 textColorGray'>
                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('aadhaar_doc')} progress={100} File={AADHAAR_DOC.adhar_doc} uploadSpeed={''} notShowBrand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    }
}

export default CreateUserForm