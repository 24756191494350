import React, { useEffect, useState, useRef } from 'react';
import InputTextField from '../../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../../ReUsableComponent/CustomAutocomplete';
import MultiSelectCheckBox from '../../../../ReUsableComponent/MultiSelectCheckBox';
import CustomDropzone from '../../../../ReUsableComponent/CustomDropzone';
import ImportIcon from '../../../../assets/svg/uploadIconForDoc.svg';
import PdfIcon from '../../../../assets/svg/pdf.svg';
import PngIcon from '../../../../assets/svg/png.svg';
import JpegIcon from '../../../../assets/svg/jpeg.svg';
import DocIcon from '../../../../assets/svg/word-file-type-svgrepo-com.svg';
import PlusIcon from '../../../../assets/svg/plus_icon.svg';
import { CustomErrorMsg } from './../../../../ReUsableComponent/CustomErrorMsg';
import { ToastErrorService, ToastSuccessService } from './../../../../ReUsableComponent/ToastNotification/ToastService';
import ProgressBarForFileUpload from './../../../../ReUsableComponent/ProgressBarForFileUpload';
import { validateTextInputAny, validateTextInputFree, validateOnlyTextInput, validateTextInput, validateEmailInput, validateNumberInput, validatePhoneNumberInput, validatePinCodeInput, validatePANInput, validateGSTINInput, validateArray, validateAccountNumberInput, validateIFSCCode, validateTextAddressInput, validateFreeTextInput, validateTextInputAnyMin, validateFreeTextInputAmpresent } from './../../../../ReUsableComponent/CustomFormValidation';
import { getCreateBrandDropdownList, getListStateCityByPincode, fileUpload } from './../../BrandManagement.service';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BrandInputForm = (
    {
        refs,
        activeStep,
        setActiveStep,
        brandOwnerDetails,
        setBrandOwnerDetails,
        brandDetails,
        setBrandDetails,
        contactDetails,
        setContactDetails,
        channelDetails,
        setChannelDetails,
        address,
        setAddress,
        companyContactDetails,
        setCompanyContactDetails,
        documentUrls,
        setDocumentUrls,
        brandHandleFormError,
        setBrandHandleFormError,
        brandOwnerDetailsFormError,
        setBrandOwnerDetailsFormError,
        brandDetailsFormError,
        setBrandDetailsFormError,
        contactDetailsFormError,
        setContactDetailsFormError,
        channelDetailsFormError,
        setChannelDetailsFormError,
        addressFormError,
        setAddressFormError,
        companyContactDetailsFormError,
        setCompanyContactDetailsFormError,
        documentUrlsFormError,
        setDocumentUrlsFormError,
        addNewContactDetailsForm,
        getBrandDetails,
        setBrandLogoImage,

        panDocumentFile,
        setPanDocumentFile,
        gstDocumentFile,
        setGstDocumentFile,
        agreementDocumentFile,
        setAgreementDocumentFile,
        uploadBrandFileLogo,
        setUploadBrandFileLogo,
        brandFloorSpaceFile,
        setBrandFloorSpaceFile,
        adjacentFile,
        setAdjacentFile,
        otherFile,
        setOtherFile,

        isFileDetailsEmpty,

        channelOfflineStatus,
        setChannelOfflineStatus,
        channelOnlineStatus,
        setChannelOnlineStatus,
    }
) => {
    const zerothTabRef = useRef(null);
    const firstTabRef = useRef(null);
    const secondTabRef = useRef(null);
    const thirdTabRef = useRef(null);
    const forthTabRef = useRef(null);
    const fifthTabRef = useRef(null);
    const sixthTabRef = useRef(null);
    const seventhTabRef = useRef(null);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const STATUS = [{ label: 'Active', value: true, name: 'status' }, { label: 'Inactive', value: false, name: 'status' }];
    const YESNOSTATUS = [{ label: 'Yes', value: true, name: 'security' }, { label: 'No', value: false, name: 'security' }];
    const getStatus = STATUS.find(option => option.value === brandDetails?.status);
    const getYesNoStatus = YESNOSTATUS.find(option => option.value === brandDetails?.security);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState([]);

    const [panDocumentFileTypeIcon, setPanDocumentFileTypeIcon] = useState("");
    const [gstDocumentFileTypeIcon, setGstDocumentFileTypeIcon] = useState("");
    const [agreementDocumentFileTypeIcon, setAgreementDocumentFileTypeIcon] = useState("");
    const [brandLogoFileTypeIcon, setBrandLogoFileTypeIcon] = useState("");
    const [brandFloorSpaceFileTypeIcon, setBrandFloorSpaceFileTypeIcon] = useState("");
    const [adjacentFileTypeIcon, setAdjacentFileTypeIcon] = useState("");
    const [otherFileTypeIcon, setOtherFileTypeIcon] = useState("");




    const brandLogoDocs = getBrandDetails?.document_details && getBrandDetails?.document_details?.filter(doc => doc.doc_type === 'brandLogo');
    const brandFloorSpaceDocs = getBrandDetails?.document_details && getBrandDetails?.document_details?.filter(doc => doc.doc_type === 'BrandFloorSpace');
    const adjacentDocs = getBrandDetails?.document_details && getBrandDetails?.document_details?.filter(doc => doc.doc_type === 'Adjacent');
    const otherDocs = getBrandDetails?.document_details && getBrandDetails?.document_details?.filter(doc => doc.doc_type === 'Other');

    const [fileUploadprogress, setFileUploadProgress] = React.useState(0);



    const [panDocumentProgress, setPanDocumentProgress] = React.useState(0);
    const [gstDocumentProgress, setGstDocumentProgress] = React.useState(0);
    const [agreementDocumentProgress, setAgreementDocumentProgress] = React.useState(0);

    const [brandLogoProgress, setBrandLogoProgress] = React.useState(0);
    const [brandFloorSpaceProgress, setbrandFloorSpaceProgress] = React.useState(0);
    const [adjacentProgress, setadjacentProgress] = React.useState(0);
    const [otherProgress, setotherProgress] = React.useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(null);

    const [panDocumentUploadSpeed, setPanDocumentUploadSpeed] = useState(null);
    const [gstDocumentUploadSpeed, setGstDocumentUploadSpeed] = useState(null);
    const [agreementDocumentUploadSpeed, setAgreementDocumentUploadSpeed] = useState(null);

    const [brandLogoUploadSpeed, setBrandLogoUploadSpeed] = useState(null);
    const [brandFloorSpaceUploadSpeed, setBrandFloorSpaceUploadSpeed] = useState(null);
    const [adjacentUploadSpeed, setAdjacentUploadSpeed] = useState(null);
    const [otherUploadSpeed, setOtherUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [dropService, setDropService] = useState('brand');
    const [dropdownKey, setDropdownKey] = useState('');
    const [documentDetails, setDocumentDetails] = useState([]);
    const [productTypeMultiSlectData, setProductTypeMultiSlectData] = useState([]);
    const [bussinessModelMultiSlectData, setBussinessModelMultiSlectData] = useState([]);
    const [channelServedMultiSlectData, setChannelServedMultiSlectData] = useState([]);
    const [channelOnlineMultiSlectData, setChannelOnlineMultiSlectData] = useState([]);
    const [channelOfflineMultiSlectData, setChannelOfflineMultiSlectData] = useState([]);
    const [branchCity, setBranchCity] = useState();
    const [branchState, setBranchState] = useState();
    const arrays = [panDocumentFile, gstDocumentFile, agreementDocumentFile, uploadBrandFileLogo, brandFloorSpaceFile, adjacentFile, otherFile];
    const merged = [].concat.apply([], arrays);
    const [selectedStatus, setSelectedStatus] = useState(getStatus || null);
    const [selectedSecurity, setSelectedSecurity] = useState(getYesNoStatus || null);
    const [inputStatus, setInputStatus] = useState('');
    const [inputStatusYesNo, setInputStatusYesNo] = useState('');

    const handleInputOutputChange = (event, newInputValue) => {
        if (newInputValue?.name === "status") {
            setInputStatus(newInputValue);
        } else {
            setInputStatusYesNo(newInputValue);
        }
    }

    const [formData, setFormData] = useState({
        productType: [],
        businessModel: []
    });
    const formatCategories = (categories) => {
        return categories.map(category => ({
            label: category,
            value: category
        }));
    };

    useEffect(() => {
        const scrollToTop = () => {
            if (activeStep === 0 && zerothTabRef.current) {
                zerothTabRef.current.scrollTop = 0;
            } else if (activeStep === 1 && firstTabRef.current) {
                firstTabRef.current.scrollTop = 0;
            } else if (activeStep === 2 && secondTabRef.current) {
                secondTabRef.current.scrollTop = 0;
            } else if (activeStep === 3 && thirdTabRef.current) {
                thirdTabRef.current.scrollTop = 0;
            } else if (activeStep === 4 && forthTabRef.current) {
                forthTabRef.current.scrollTop = 0;
            } else if (activeStep === 5 && fifthTabRef.current) {
                fifthTabRef.current.scrollTop = 0;
            } else if (activeStep === 6 && sixthTabRef.current) {
                sixthTabRef.current.scrollTop = 0;
            } else if (activeStep === 7 && seventhTabRef.current) {
                seventhTabRef.current.scrollTop = 0;
            }
        };
        setTimeout(scrollToTop, 100);
    }, [activeStep]);

    useEffect(() => {
        handleGetAllDropdownList();
    }, []);

    useEffect(() => {
        setDocumentUrls(merged);
    }, [panDocumentFile, gstDocumentFile, agreementDocumentFile, uploadBrandFileLogo, brandFloorSpaceFile, adjacentFile, otherFile]);

    const handleGetAllDropdownList = () => {
        const param = `?service=${dropService}&dropdown_key=${dropdownKey}`
        getCreateBrandDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const findDropdownByKey = (dropdownKey) => {
                    return response?.data?.data?.find(entry => entry.dropdown_key === dropdownKey);
                };
                response?.data?.data?.map((item) => {
                    if (item?.dropdown_key === "ProductType") {
                        setProductTypeMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "BussinessModel") {
                        setBussinessModelMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelServed") {
                        setChannelServedMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelOnline") {
                        setChannelOnlineMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelOffline") {
                        setChannelOfflineMultiSlectData(formatCategories(item?.dropdown_values));
                    }
                });
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    function getFileType(fileName) {
        if (!fileName) {
            return '';
        }
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    }

    const handleFileDrop = (name, files) => {
        const file = files[0];
        const size = files[0]?.size;
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const allowedTypesForLogo = ['image/png', 'image/jpeg', 'image/jpg'];
        if (name === 'brandLogo') {
            if (allowedTypesForLogo.includes(file.type)) {
                setSelectedFile(file);
                setFileUploadProgress(0);
                simulateUpload(name);

                if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Brand Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Brand Logo', JpegIcon, size);
                }

                if (name === 'brandLogo') {
                    setBrandLogoProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a JPEG, JPG or PNG file Only.');

            }
        } else {
            if (allowedTypes.includes(file.type)) {
                setSelectedFile(file);
                setFileUploadProgress(0);
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Brand Documents', PdfIcon, size);
                } else if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Brand Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Brand Logo', JpegIcon, size);
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    handleFileUpload(name, file, 'Brand Documents', DocIcon, size);
                }

                if (name === 'PANDocument') {
                    setPanDocumentProgress(0);
                } else if (name === 'GSTDocument') {
                    setGstDocumentProgress(0);
                } else if (name === 'AgreementDocument') {
                    setAgreementDocumentProgress(0);
                } else if (name === 'brandLogo') {
                    setBrandLogoProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a PDF, JPEG, JPG or PNG file Only.');
            }
        }
    };

    const simulateUpload = (name) => {
        const interval = setInterval(() => {
            setFileUploadProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000;
                const speed = (nextProgress / elapsedTime).toFixed(2);
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
        if (name === 'PANDocument') {
            const interval = setInterval(() => {
                setPanDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setPanDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setPanDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GSTDocument') {
            const interval = setInterval(() => {
                setGstDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'AgreementDocument') {
            const interval = setInterval(() => {
                setAgreementDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAgreementDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAgreementDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'brandLogo') {
            const interval = setInterval(() => {
                setBrandLogoProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setBrandLogoUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setBrandLogoUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'BrandFloorSpace') {
            const interval = setInterval(() => {
                setbrandFloorSpaceProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setBrandFloorSpaceUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setBrandFloorSpaceUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'Adjacent') {
            const interval = setInterval(() => {
                setadjacentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAdjacentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAdjacentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        } else if (name === 'Other') {
            const interval = setInterval(() => {
                setotherProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setOtherUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setOtherUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        }

    };

    const renderFileIcons = (file) => {
        if (getFileType(file.doc_name) === 'docx') {
            return DocIcon
        } else if (getFileType(file.doc_name) === "pdf") {
            return PdfIcon
        } else if (getFileType(file.doc_name) === "png") {
            return PngIcon
        } else if (getFileType(file.doc_name) === "jpg" || getFileType(file.doc_name) === "jpeg") {
            return JpegIcon
        }
    }

    const handleFileUpload = async (name, file, fileServiceType, fileTypeIcon, size) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('service', 'Brand_Documents');
        formData.append('file_name', 'Data');
        formData.append('service_type', fileServiceType);
        formData.append('size', size);
        formData.append('brand_name', brandDetails?.brand_name ? brandDetails?.brand_name : '');

        fileUpload(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message)
            } else {
                if (name === 'PANDocument') {
                    setPanDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "PANDocument",
                        }
                    );
                    setPanDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'GSTDocument') {
                    setGstDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GSTDocument",
                        }
                    );
                    setGstDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'AgreementDocument') {
                    setAgreementDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "AgreementDocument",
                        }
                    );
                    setAgreementDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'brandLogo') {
                    setUploadBrandFileLogo(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "brandLogo",
                        }
                    );
                    setBrandLogoImage(response?.data?.data?.file_url);
                    setBrandLogoFileTypeIcon(fileTypeIcon);
                } else if (name === 'BrandFloorSpace') {
                    setBrandFloorSpaceFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "BrandFloorSpace",
                        }
                    );
                    setBrandFloorSpaceFileTypeIcon(fileTypeIcon);
                } else if (name === 'Adjacent') {
                    setAdjacentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Adjacent",
                        }
                    );
                    setAdjacentFileTypeIcon(fileTypeIcon);
                } else if (name === 'Other') {
                    setOtherFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Other",
                        }
                    );
                    setOtherFileTypeIcon(fileTypeIcon);
                }
                setUploadedFile(response?.data?.data);

            }
        }).catch(error => {
        }).finally(() => {
            // setLoading(false);
        })
    };

    const removeFile = (name) => {
        setUploadedFile([]);
        if (name === 'PANDocument') {
            setPanDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
        } else if (name === 'GSTDocument') {
            setGstDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
        } else if (name === 'AgreementDocument') {
            setAgreementDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
        } else if (name === 'brandLogo') {
            setUploadBrandFileLogo({
                file_id: '',
                doc_name: '',
                doc_url: '',
                size: '',
                created_at: '',
                doc_type: '',
            });
            setBrandLogoImage("");
        } else if (name === 'BrandFloorSpace') {
            setBrandFloorSpaceFile({
                file_id: '',
                doc_name: '',
                doc_url: '',
                size: '',
                created_at: '',
                doc_type: '',
            })
        } else if (name === 'Adjacent') {
            setAdjacentFile({
                file_id: '',
                doc_name: '',
                doc_url: '',
                size: '',
                created_at: '',
                doc_type: '',
            })
        } else if (name === 'Other') {
            setOtherFile({
                file_id: '',
                doc_name: '',
                doc_url: '',
                size: '',
                created_at: '',
                doc_type: '',
            })
        }
    }

    useEffect(() => {
        setDocumentUrls([panDocumentFile, gstDocumentFile, agreementDocumentFile, uploadBrandFileLogo, brandFloorSpaceFile, adjacentFile, otherFile])
    }, [panDocumentFile, gstDocumentFile, agreementDocumentFile, uploadBrandFileLogo, brandFloorSpaceFile, adjacentFile, otherFile])




    const handlePinCode = (name, value, companyContactDetailsBranchPincode) => {
        const param = `?pincode=${value}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {

                let stateData = response?.data?.data?.map((item) => ({
                    label: item?.state,
                    value: item?.state,
                    name: 'state'
                }));
                const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                    return stateData.find(item => item.value === value);
                });

                let cityData = response?.data?.data?.map((item) => ({
                    label: item?.city,
                    value: item?.city,
                    name: 'city'
                }));
                const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                    return cityData.find(item => item.value === value);
                });

                let regionData = response?.data?.data?.map((item) => ({
                    label: item?.region,
                    value: item?.region,
                    name: 'region'
                }));
                const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                    return regionData.find(item => item.value === value);
                });

                let areaData = response?.data?.data?.map((item) => ({
                    label: item?.area,
                    value: item?.area,
                    name: 'area'
                }));
                const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                    return areaData.find(item => item.value === value);
                });

                if (name === 'registered_office_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        registered_office_address: {
                            ...prevState.registered_office_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'warehouse_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        warehouse_address: {
                            ...prevState.warehouse_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'return_warehouse_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        return_warehouse_address: {
                            ...prevState.return_warehouse_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'billing_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        billing_address: {
                            ...prevState.billing_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if ((name === 'branch_pincode') && (companyContactDetailsBranchPincode === "companyContactDetailsBranchPincode")) {
                    setCompanyContactDetails(prevState => ({
                        ...prevState,
                        branch_city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                        branch_state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                    }));
                } else {
                    setBrandDetails(prevState => ({
                        ...prevState,
                        branch_city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                        branch_state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                    }));
                }
                setBranchCity(cityData);
                setBranchState(stateData);
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }



    const handleChannelChange = (name, event) => {
        const { target: { value } } = event;
        if (name === "product_type" || name === "business_model") {
            setBrandDetails(prevState => ({
                ...prevState,
                [name]: value
            }))
            if (name === "product_type") {
                setBrandDetailsFormError((prevErrors) => ({
                    ...prevErrors,
                    product_type: null,
                }));
            }

        } else if (name === "channel_served" || name === "channel_offline" || name === "channel_online") {
            if (name === "channel_served") {
                setChannelOfflineStatus(true);
                setChannelOnlineStatus(true);
                value?.forEach(status => {
                    if (status === 'Offline') {
                        setChannelOfflineStatus(false);
                    } else if (status === 'Online') {
                        setChannelOnlineStatus(false);
                    }
                });
            }
            setChannelDetails(prevState => ({
                ...prevState,
                [name]: value
            }));

        }
    };

    const handleDropDownChange = (newValue, setState, validationFunc, setErrorState, errorMsg) => {
        const { name, value } = newValue;
        // if (validationFunc(value)) {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        //     setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        // } else {
        //     setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        // }
    }

    const handleInputArrayChange = (event, index, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        const { name, value } = event.target;
        const updatedContactDetails = [...contactDetails];
        if (name === "email") {
            updatedContactDetails[index][name] = value.toLowerCase()
        } else {
            updatedContactDetails[index][name] = value;
        }

        setState(updatedContactDetails);

        if (validationFunc(value)) {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        } else {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        }
    }

    const onChangeCOF = (event, newValue) => {
        handleDropDownChange(newValue, setBrandDetails, validateTextInputAny, setBrandHandleFormError, CustomErrorMsg.name);
        if (newValue?.name === "status") {
            setSelectedStatus(newValue);
        } else {
            setSelectedSecurity(newValue);
        }
    }



    const handleInputChange = (event, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        const { name, value } = event.target;
        const keys = name.split('.');
        setState(prevState => {
            let updatedState = { ...prevState };

            if (keys.length === 1) {
                updatedState[keys[0]] = value;
            } else {
                let nestedObj = updatedState;
                for (let i = 0; i < keys.length - 1; i++) {
                    nestedObj = nestedObj[keys[i]];
                }
                nestedObj[keys[keys.length - 1]] = value;
            }

            return updatedState;
        });
        if (validationFunc(value)) {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = null;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = null;
                }
                return updatedState;
            });

            let conditionPinCode = (value) => {
                if (value.length === 6 && /^\d+$/.test(value)) {
                    return true;
                }
                return false;
            }

            if (conditionPinCode) {
                if (name === "registered_office_address.pincode" || name === 'warehouse_address.pincode' || name === 'return_warehouse_address.pincode' || name === 'billing_address.pincode' || name === 'branch_pincode') {
                    handlePinCode(name, value, companyContactDetailsBranchPincode);
                }
            }
        } else {
            setErrorState(prevState => {
                let updatedState = { ...prevState };

                if (keys.length === 1) {
                    updatedState[keys[0]] = errorMsg;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = errorMsg;
                }

                return updatedState;
            });
        }
    };

    const handleBrandOwnerDetailsChange = (event) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputChange(event, setBrandOwnerDetails, validateTextInput, setBrandOwnerDetailsFormError, CustomErrorMsg.name);
        } else if (name === 'mobile') {
            handleInputChange(event, setBrandOwnerDetails, validateNumberInput, setBrandOwnerDetailsFormError, CustomErrorMsg.mobile);
        } else if (name === 'email') {
            handleInputChange(event, setBrandOwnerDetails, validateEmailInput, setBrandOwnerDetailsFormError, CustomErrorMsg.email);
        }
    };

    const handleBrandDetailsChange = (event, newValue) => {
        const { name, value } = event.target;
        if (name === 'brand_name') {
            handleInputChange(event, setBrandDetails, validateTextInputAnyMin, setBrandDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'status') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'brand_deck') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'email_id') {
            handleInputChange(event, setBrandDetails, validateEmailInput, setBrandDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'brand_mobile') {
            handleInputChange(event, setBrandDetails, validateNumberInput, setBrandDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'product_type') {
            handleInputChange(event, setBrandDetails, validateArray, setBrandDetailsFormError, CustomErrorMsg.product, '');
        } else if (name === 'pan') {
            handleInputChange(event, setBrandDetails, validatePANInput, setBrandDetailsFormError, CustomErrorMsg.pan, '');
        } else if (name === 'gstin') {
            handleInputChange(event, setBrandDetails, validateGSTINInput, setBrandDetailsFormError, CustomErrorMsg.gstin, '');
        } else if (name === 'security_comment') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.securityComment, '');
        } else if (name === 'account_name') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.accountName, '');
        } else if (name === 'bank_name') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.bankName, '');
        } else if (name === 'account_number') {
            handleInputChange(event, setBrandDetails, validateAccountNumberInput, setBrandDetailsFormError, CustomErrorMsg.accountNumber, '');
        } else if (name === 'ifsc_code') {
            handleInputChange(event, setBrandDetails, validateIFSCCode, setBrandDetailsFormError, CustomErrorMsg.ifscCode, '');
        } else if (name === 'branch') {
            handleInputChange(event, setBrandDetails, validateTextInputAnyMin, setBrandDetailsFormError, CustomErrorMsg.branch, '');
        } else if (name === 'branch_address') {
            handleInputChange(event, setBrandDetails, validateTextAddressInput, setBrandDetailsFormError, CustomErrorMsg.address, '');
        } else if (name === 'branch_pincode') {
            handleInputChange(event, setBrandDetails, validatePinCodeInput, setBrandDetailsFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'branch_city') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.city, '');
        } else if (name === 'branch_state') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.state);
        }
    };

    const handleBrandContactsDetailsChange = (event, index) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputArrayChange(event, index, setContactDetails, validateTextInput, setContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_dept') {
            handleInputArrayChange(event, index, setContactDetails, validateFreeTextInputAmpresent, setContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_mobile') {
            handleInputArrayChange(event, index, setContactDetails, validateNumberInput, setContactDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'email') {
            handleInputArrayChange(event, index, setContactDetails, validateEmailInput, setContactDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'person_other') {
            handleInputArrayChange(event, index, setContactDetails, validateTextInput, setContactDetailsFormError, CustomErrorMsg.name, '');
        }
    }

    const handleChannelDetailsChange = (event) => {

    }

    const handleBrandAddressChange = (event) => {
        const { name, value } = event.target;
        if (name === 'registered_office_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'registered_office_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'registered_office_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'registered_office_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'registered_office_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'registered_office_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'registered_office_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        }

        else if (name === 'warehouse_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'warehouse_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'warehouse_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'warehouse_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'warehouse_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'warehouse_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'warehouse_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'warehouse_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'warehouse_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }

        else if (name === 'return_warehouse_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'return_warehouse_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'return_warehouse_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'return_warehouse_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'return_warehouse_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'return_warehouse_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'return_warehouse_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'return_warehouse_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'return_warehouse_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }

        else if (name === 'billing_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'billing_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'billing_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'billing_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'billing_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'billing_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'billing_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'billing_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'billing_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }
    }

    const handleCompanyContactDetailsChange = (event) => {
        const { name, value } = event.target;
        if (name === 'contacts_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'contacts_mobile') {
            handleInputChange(event, setCompanyContactDetails, validateNumberInput, setCompanyContactDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'contacts_email_id') {
            handleInputChange(event, setCompanyContactDetails, validateEmailInput, setCompanyContactDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'contacts_person_dept') {
            handleInputChange(event, setCompanyContactDetails, validateFreeTextInputAmpresent, setCompanyContactDetailsFormError, CustomErrorMsg.department, '');
        } else if (name === 'account_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.accountName, '');
        } else if (name === 'bank_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.bankName, '');
        } else if (name === 'account_number') {
            handleInputChange(event, setCompanyContactDetails, validateAccountNumberInput, setCompanyContactDetailsFormError, CustomErrorMsg.accountNumber, '');
        }

        else if (name === 'ifsc_code') {
            handleInputChange(event, setCompanyContactDetails, validateIFSCCode, setCompanyContactDetailsFormError, CustomErrorMsg.ifscCode, '');
        } else if (name === 'branch') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.branch, '');
        } else if (name === 'branch_address') {
            handleInputChange(event, setCompanyContactDetails, validateTextAddressInput, setCompanyContactDetailsFormError, CustomErrorMsg.address, '');
        } else if (name === 'branch_pincode') {
            handleInputChange(event, setCompanyContactDetails, validatePinCodeInput, setCompanyContactDetailsFormError, CustomErrorMsg.pincode, 'companyContactDetailsBranchPincode');
        } else if (name === 'branch_city') {
            handleInputChange(event, setCompanyContactDetails, validateTextInputAny, setCompanyContactDetailsFormError, CustomErrorMsg.city, '');
        } else if (name === 'branch_state') {
            handleInputChange(event, setCompanyContactDetails, validateTextInputAny, setCompanyContactDetailsFormError, CustomErrorMsg.state, '');
        }
    }
    const handleDocumentUrlsChange = (event) => {
        const { name, value } = event.target;
        handleInputChange(event, setDocumentUrls, validateTextInputAny, setDocumentUrlsFormError, CustomErrorMsg.name, '');
    }






    const [isSameRegisteredOfficeAddressAddress, setIsSameRegisteredOfficeAddressAddress] = useState(false);
    const [isSameWarehouseAddress, setIsSameWarehouseAddress] = useState(false);
    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [billingChecked, setBillingChecked] = useState({ registeredOffice: false, warehouse: false, });

    const initialState = {
        address: '',
        region: '',
        state: '',
        city: '',
        area: '',
        pincode: '',
        email: '',
        mobile: '',
        person_mobile: '',
    };

    const initialStateNull = {
        address: null,
        region: null,
        state: null,
        city: null,
        area: null,
        pincode: null,
        email: null,
        mobile: null,
        person_mobile: null,
    };

    const handleCopyAddress = (e, sourceKey, targetKey, setIsSame, formErrorKey) => {
        const isChecked = e.target.checked;
        setIsSame(isChecked);

        if (isChecked) {
            const sourceAddress = address?.[sourceKey];

            setAddress((prevDetails) => ({
                ...prevDetails,
                [targetKey]: { ...sourceAddress },
            }));

            // Reset form error when copying
            // setAddressFormError((prevErrors) => ({
            //     ...prevErrors,
            //     [formErrorKey]: initialStateNull,
            // }));

            const newFormError = {};
            for (let key in initialState) {
                if (!sourceAddress?.[key]) {
                    newFormError[key] = ''; // Set error as empty string
                } else {
                    newFormError[key] = null; // Clear the error
                }
            }

            // Set the form error for the target address
            setAddressFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...newFormError },
            }));

        } else {
            setAddress((prevDetails) => ({
                ...prevDetails,
                [targetKey]: initialState,
            }));

            // Instead of resetting errors to the initial state, only remove them
            // to prevent showing errors immediately when unchecked.
            setAddressFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...initialStateNull },
            }));
        }
    };

    // Example usage for each checkbox:
    const handleCopyRegisteredOfficeAddress = (e) => {
        handleCopyAddress(e, 'registered_office_address', 'warehouse_address', setIsSameRegisteredOfficeAddressAddress, 'warehouse_address');
    }

    const handleCopyWarehouseAddress = (e) => {
        handleCopyAddress(e, 'warehouse_address', 'return_warehouse_address', setIsSameWarehouseAddress, 'return_warehouse_address');
    }

    const handleCopyRegisteredOfficeAddressForBillingPlace = (e) => {
        setBillingChecked({
            registeredOffice: !billingChecked.registeredOffice,
            warehouse: false, // Uncheck the other checkbox
        });
        handleCopyAddress(e, 'registered_office_address', 'billing_address', setIsSameAsBilling, 'billing_address');
    }

    const handleCopyWarehouseAddressForBillingPlace = (e) => {
        setBillingChecked({
            registeredOffice: false, // Uncheck the other checkbox
            warehouse: !billingChecked.warehouse,
        });
        handleCopyAddress(e, 'warehouse_address', 'billing_address', setIsSameAsBilling, 'billing_address');
    }








    // Input Form Start
    const displayFormContent = () => {
        if (activeStep === 0) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Brand Owner Details
                </div>
                <div ref={zerothTabRef} className='pt-3 formContainer customScroll'>
                    <div className='inputBottomSpace' ref={refs.brandOwnerDetails.person_name}>
                        <InputTextField type='text' disabled={false} name='person_name' value={brandOwnerDetails?.person_name} onChange={handleBrandOwnerDetailsChange} required minLength={2} maxLength={100} label='Owner Name' />
                        {/* {ownerNameError && <div className="error">{ownerNameError}</div>} */}
                        {brandOwnerDetailsFormError.person_name && <div className="error">{brandOwnerDetailsFormError.person_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandOwnerDetails.mobile}>
                        <InputTextField type='text' disabled={false} name='mobile' value={brandOwnerDetails?.mobile} onChange={handleBrandOwnerDetailsChange} required minLength={10} maxLength={10} label='Mobile Number' />
                        {/* {mobileNumberError && <div className="error">{mobileNumberError}</div>} */}
                        {brandOwnerDetailsFormError.mobile && <div className="error">{brandOwnerDetailsFormError.mobile}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandOwnerDetails.email}>
                        <InputTextField type='email' disabled={false} name='email' value={brandOwnerDetails?.email} onChange={handleBrandOwnerDetailsChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                        {/* {emailError && <div className="error">{emailError}</div>} */}
                        {brandOwnerDetailsFormError.email && <div className="error">{brandOwnerDetailsFormError.email}</div>}
                    </div>
                </div>
            </div>
        } else if (activeStep === 1) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Brand Details
                </div>
                <div ref={firstTabRef} className='pt-3 BrandDetailsId formContainer customScroll'>
                    <div className='inputBottomSpace' ref={refs.brandDetails.brand_name}>
                        <InputTextField type='text' disabled={false} name={'brand_name'} value={brandDetails?.brand_name} onChange={handleBrandDetailsChange} required minLength={2} maxLength={100} label='Brand Name' />
                        {brandDetailsFormError.brand_name && <div className="error">{brandDetailsFormError.brand_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.status}>
                        <CustomAutoComplete onChange={onChangeCOF} inputValue={inputStatus} onInputChange={handleInputOutputChange} value={selectedStatus} name={'status'} options={STATUS} required minLength={''} maxLength={''} label='Brand Status*' />
                        {brandDetailsFormError.status && <div className="error">{brandDetailsFormError.status}</div>}
                    </div>
                    <div className='inputBottomSpace' >
                        <InputTextField type='text' disabled={false} name={'brand_deck'} value={brandDetails?.brand_deck} onChange={handleBrandDetailsChange} minLength={2} maxLength={100} label='Brand Deck' />
                        {/* {brandDetailsFormError.brand_deck && <div className="error">{brandDetailsFormError.brand_deck}</div>} */}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.email_id}>
                        <InputTextField type='email' disabled={false} name={'email_id'} value={brandDetails?.email_id} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Email Id' />
                        {brandDetailsFormError.email_id && <div className="error">{brandDetailsFormError.email_id}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.brand_mobile}>
                        <InputTextField type='text' disabled={false} name={'brand_mobile'} value={brandDetails?.brand_mobile} onChange={handleBrandDetailsChange} required minLength={10} maxLength={10} label='Brand Phone Number' />
                        {brandDetailsFormError.brand_mobile && <div className="error">{brandDetailsFormError.brand_mobile}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.product_type}>
                        <MultiSelectCheckBox selectedValue={brandDetails?.product_type} name={'product_type'} handleChange={(event) => handleChannelChange('product_type', event)} options={productTypeMultiSlectData} MenuProps={MenuProps} label='Product Type*' />
                        {brandDetailsFormError?.product_type && <div className="error">{brandDetailsFormError?.product_type}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox selectedValue={brandDetails?.business_model} name={'business_model'} handleChange={(event) => handleChannelChange('business_model', event)} options={bussinessModelMultiSlectData} MenuProps={MenuProps} label='Business Model' />
                        {brandDetailsFormError?.business_model && <div className="error">{brandDetailsFormError?.business_model}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.pan}>
                        <InputTextField type='text' disabled={false} name={'pan'} value={brandDetails?.pan} onChange={handleBrandDetailsChange} required minLength={10} maxLength={10} label='Pan Number' />
                        {brandDetailsFormError.pan && <div className="error">{brandDetailsFormError.pan}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.gstin}>
                        <InputTextField type='text' disabled={false} name={'gstin'} value={brandDetails?.gstin} onChange={handleBrandDetailsChange} required minLength={15} maxLength={15} label='GSTIN' />
                        {brandDetailsFormError.gstin && <div className="error">{brandDetailsFormError.gstin}</div>}
                    </div>
                    <div className='inputBottomSpace' >
                        <CustomAutoComplete onChange={onChangeCOF} inputValue={inputStatusYesNo} onInputChange={handleInputOutputChange} value={selectedSecurity} name={'security'} required options={YESNOSTATUS} label='Security Status' />
                        {brandDetailsFormError.security && <div className="error">{brandDetailsFormError.security}</div>}
                    </div>
                    <div className='inputBottomSpace' >
                        <InputTextField type='text' disabled={false} name={'security_comment'} value={brandDetails?.security_comment} onChange={handleBrandDetailsChange} minLength={2} maxLength={200} label='Security Comments' />
                        {/* {brandDetailsFormError.security_comment && <div className="error">{brandDetailsFormError.security_comment}</div>} */}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.account_name}>
                        <InputTextField type='text' disabled={false} name={'account_name'} value={brandDetails?.account_name} onChange={handleBrandDetailsChange} required minLength={2} maxLength={100} label='Account Name' />
                        {brandDetailsFormError.account_name && <div className="error">{brandDetailsFormError.account_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.bank_name}>
                        <InputTextField type='text' disabled={false} name={'bank_name'} value={brandDetails?.bank_name} onChange={handleBrandDetailsChange} required minLength={2} maxLength={40} label='Bank Name' />
                        {brandDetailsFormError.bank_name && <div className="error">{brandDetailsFormError.bank_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.account_number}>
                        <InputTextField type='text' disabled={false} name={'account_number'} value={brandDetails?.account_number} onChange={handleBrandDetailsChange} minLength={6} maxLength={16} label='Account Number*' />
                        {brandDetailsFormError.account_number && <div className="error">{brandDetailsFormError.account_number}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.ifsc_code}>
                        <InputTextField type='text' disabled={false} name={'ifsc_code'} value={brandDetails?.ifsc_code} onChange={handleBrandDetailsChange} required minLength={11} maxLength={11} label='IFSC Code' />
                        {brandDetailsFormError.ifsc_code && <div className="error">{brandDetailsFormError.ifsc_code}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.branch}>
                        <InputTextField type='text' disabled={false} name={'branch'} value={brandDetails?.branch} onChange={handleBrandDetailsChange} required minLength={2} maxLength={80} label='Branch' />
                        {brandDetailsFormError.branch && <div className="error">{brandDetailsFormError.branch}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.brandDetails.branch_address}>
                        <InputTextField type='text' disabled={false} name={'branch_address'} value={brandDetails?.branch_address} onChange={handleBrandDetailsChange} required minLength={10} maxLength={80} label='Branch Address' />
                        {brandDetailsFormError.branch_address && <div className="error">{brandDetailsFormError.branch_address}</div>}
                    </div>
                    {/* <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={false} name={'branch_pincode'} value={brandDetails?.branch_pincode} onChange={handleBrandDetailsChange} required minLength={6} maxLength={6}  label='Branch Pin Code' />
                        {brandDetailsFormError.branch_pincode && <div className="error">{brandDetailsFormError.branch_pincode}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={true} name={'branch_city'} value={brandDetails?.branch_city} required minLength={''} maxLength={''}  label='Branch City' />
                        {brandDetailsFormError.branch_city && <div className="error">{brandDetailsFormError.branch_city}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={true} name={'branch_state'} value={brandDetails?.branch_state} required minLength={''} maxLength={''}  label='Branch State' />
                        {brandDetailsFormError.branch_state && <div className="error">{brandDetailsFormError.branch_state}</div>}
                    </div> */}
                </div>
            </div>
        } else if (activeStep === 2) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Brand Contacts
                </div>
                <div className='brandContactsDetals customScroll'>
                    <div ref={secondTabRef} className='' style={{ position: 'relative' }}>
                        {
                            contactDetails?.map((item, index) => (
                                <>
                                    <div className='pt-3'>
                                        <strong>Contacts {index + 1}</strong>
                                    </div>

                                    <div className='pt-3 formContainer' key={index}>
                                        <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_name : null}>
                                            <InputTextField type='text' disabled={false} name={'person_name'} value={item?.person_name} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={2} maxLength={80} label='Contact Name' />
                                            {index === 0 && contactDetailsFormError?.person_name && <div className="error">{contactDetailsFormError?.person_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_dept : null}>
                                            <InputTextField type='text' disabled={false} name={'person_dept'} value={item?.person_dept} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={2} maxLength={40} label='Contact’s Person Department' />
                                            {index === 0 && contactDetailsFormError?.person_dept && <div className="error">{contactDetailsFormError?.person_dept}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_mobile : null}>
                                            <InputTextField type='text' disabled={false} name={'person_mobile'} value={item?.person_mobile} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={10} maxLength={10} label='Contact’s Number' />
                                            {index === 0 && contactDetailsFormError?.person_mobile && <div className="error">{contactDetailsFormError?.person_mobile}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.email : null}>
                                            <InputTextField type='text' disabled={false} name={'email'} value={item?.email} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={''} label='Email Id' />
                                            {index === 0 && contactDetailsFormError?.email && <div className="error">{contactDetailsFormError?.email}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_other : null}>
                                            <InputTextField type='text' disabled={false} name={'person_other'} value={item?.person_other} onChange={(event) => handleBrandContactsDetailsChange(event, index)} minLength={''} maxLength={''} label='Contact Person Other Details' />
                                            {/* {contactDetailsFormError.person_other && <div className="error">{contactDetailsFormError.person_other}</div>} */}
                                        </div>
                                    </div>
                                </>
                            ))
                        }

                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', right: '15px', bottom: '-30px' }} >
                            <div onClick={addNewContactDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                <img src={PlusIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (activeStep === 3) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Channel Details
                </div>
                <div ref={thirdTabRef} className='pt-3 formContainer customScroll'>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox selectedValue={channelDetails?.channel_served} name={'channel_served'} handleChange={(event) => handleChannelChange('channel_served', event)} options={channelServedMultiSlectData} MenuProps={MenuProps} label='Channel Served' />
                        {channelDetailsFormError.channel_served && <div className="error">{channelDetailsFormError.channel_served}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox selectedValue={channelOfflineStatus ? [] : channelDetails?.channel_offline} name={'channel_offline'} handleChange={(event) => handleChannelChange('channel_offline', event)} options={channelOfflineMultiSlectData} MenuProps={MenuProps} disabled={channelOfflineStatus} label='Offline Channel' />
                        {channelDetailsFormError.channel_offline && <div className="error">{channelDetailsFormError.channel_offline}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox selectedValue={channelOnlineStatus ? [] : channelDetails?.channel_online} name={'channel_online'} handleChange={(event) => handleChannelChange('channel_online', event)} options={channelOnlineMultiSlectData} MenuProps={MenuProps} disabled={channelOnlineStatus} label='Online Channel' />
                        {channelDetailsFormError.channel_online && <div className="error">{channelDetailsFormError.channel_online}</div>}
                    </div>
                </div>
            </div>
        } else if (activeStep === 4) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Addresses Details
                </div>
                <div ref={forthTabRef} className='pt-3 customScroll addressDetailsMainCont'>
                    <div className='addressDetailsCont'>
                        <div>
                            <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>Registered Office Address</div>
                            <div className='formContainer addressesDetailsCont'>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.address}>
                                    <InputTextField type='text' disabled={false} name={'registered_office_address.address'} value={address?.registered_office_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                    {addressFormError?.registered_office_address?.address && <div className="error">{addressFormError?.registered_office_address?.address}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.pincode}>
                                    <InputTextField type='text' disabled={false} name={'registered_office_address.pincode'} value={address?.registered_office_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                    {/* <InputTextField type='text' disabled={false} name={'registered_office_address.pincode'} value={address?.registered_office_address?.pincode} onChange={handleBrandAddressChange} required minLength={''} maxLength={''}  label='Pincode' /> */}
                                    {addressFormError?.registered_office_address?.pincode && <div className="error">{addressFormError?.registered_office_address?.pincode}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.area}>
                                    <InputTextField type='text' disabled={false} name={'registered_office_address.area'} value={address?.registered_office_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                    {addressFormError?.registered_office_address?.area && <div className="error">{addressFormError?.registered_office_address?.area}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.city}>
                                    <InputTextField type='text' name={'registered_office_address.city'} value={address?.registered_office_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                    {addressFormError?.registered_office_address?.city && <div className="error">{addressFormError?.registered_office_address?.city}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.region}>
                                    <InputTextField type='text' disabled={true} name={'registered_office_address.region'} value={address?.registered_office_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                    {addressFormError?.registered_office_address?.region && <div className="error">{addressFormError?.registered_office_address?.region}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.state}>
                                    <InputTextField type='text' disabled={true} name={'registered_office_address.state'} value={address?.registered_office_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                    {addressFormError?.registered_office_address?.state && <div className="error">{addressFormError?.registered_office_address?.state}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.registered_office_address.email}>
                                    <InputTextField type='text' disabled={false} name={'registered_office_address.email'} value={address?.registered_office_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                    {addressFormError?.registered_office_address?.email && <div className="error">{addressFormError?.registered_office_address?.email}</div>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                Warehouse Address
                                <span className='copySameAsAbove'>
                                    <input type='checkbox' className='form-check-input' id='sameAsAbove' onChange={handleCopyRegisteredOfficeAddress} />
                                    <label className='form-check-label' htmlFor='sameAsAbove'>
                                        Same as Registered Office Address
                                    </label>
                                </span>
                            </div>
                            <div className='formContainer addressesDetailsCont'>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.address}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.address'} value={address?.warehouse_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                    {addressFormError?.warehouse_address?.address && <div className="error">{addressFormError?.warehouse_address?.address}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.pincode}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.pincode'} value={address?.warehouse_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                    {addressFormError?.warehouse_address?.pincode && <div className="error">{addressFormError?.warehouse_address?.pincode}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.area}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.area'} value={address?.warehouse_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                    {addressFormError?.warehouse_address?.area && <div className="error">{addressFormError?.warehouse_address?.area}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.city}>
                                    <InputTextField type='text' name={'warehouse_address.city'} value={address?.warehouse_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                    {addressFormError?.warehouse_address?.city && <div className="error">{addressFormError?.warehouse_address?.city}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.region}>
                                    <InputTextField type='text' disabled={true} name={'warehouse_address.region'} value={address?.warehouse_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                    {addressFormError?.warehouse_address?.region && <div className="error">{addressFormError?.warehouse_address?.region}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.state}>
                                    <InputTextField type='text' disabled={true} name={'warehouse_address.state'} value={address?.warehouse_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                    {addressFormError?.warehouse_address?.state && <div className="error">{addressFormError?.warehouse_address?.state}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.email}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.email'} value={address?.warehouse_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                    {addressFormError?.warehouse_address?.email && <div className="error">{addressFormError?.warehouse_address?.email}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.mobile}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.mobile'} value={address?.warehouse_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Mobile Number' />
                                    {addressFormError?.warehouse_address?.mobile && <div className="error">{addressFormError?.warehouse_address?.mobile}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.warehouse_address.person_mobile}>
                                    <InputTextField type='text' disabled={false} name={'warehouse_address.person_mobile'} value={address?.warehouse_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                    {addressFormError?.warehouse_address?.person_mobile && <div className="error">{addressFormError?.warehouse_address?.person_mobile}</div>}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                Return Warehouse Address
                                <span className='copySameAsAbove'>
                                    <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyWarehouseAddress} />
                                    <label className='form-check-label' htmlFor='sameAsBilling'>
                                        Same as Warehouse Address
                                    </label>
                                </span>
                            </div>
                            <div className='formContainer addressesDetailsCont'>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.address}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.address'} value={address?.return_warehouse_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                    {addressFormError?.return_warehouse_address?.address && <div className="error">{addressFormError?.return_warehouse_address?.address}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.pincode}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.pincode'} value={address?.return_warehouse_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                    {addressFormError?.return_warehouse_address?.pincode && <div className="error">{addressFormError?.return_warehouse_address?.pincode}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.area}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.area'} value={address?.return_warehouse_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                    {addressFormError?.return_warehouse_address?.area && <div className="error">{addressFormError?.return_warehouse_address?.area}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.city}>
                                    <InputTextField type='text' name={'return_warehouse_address.city'} value={address?.return_warehouse_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                    {addressFormError?.return_warehouse_address?.city && <div className="error">{addressFormError?.return_warehouse_address?.city}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.region}>
                                    <InputTextField type='text' disabled={true} name={'return_warehouse_address.region'} value={address?.return_warehouse_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                    {addressFormError?.return_warehouse_address?.region && <div className="error">{addressFormError?.return_warehouse_address?.region}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.state}>
                                    <InputTextField type='text' disabled={true} name={'return_warehouse_address.state'} value={address?.return_warehouse_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                    {addressFormError?.return_warehouse_address?.state && <div className="error">{addressFormError?.return_warehouse_address?.state}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.email}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.email'} value={address?.return_warehouse_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                    {addressFormError?.return_warehouse_address?.email && <div className="error">{addressFormError?.return_warehouse_address?.email}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.mobile}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.mobile'} value={address?.return_warehouse_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Place Mobile Number' />
                                    {addressFormError?.return_warehouse_address?.mobile && <div className="error">{addressFormError?.return_warehouse_address?.mobile}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.return_warehouse_address.person_mobile}>
                                    <InputTextField type='text' disabled={false} name={'return_warehouse_address.person_mobile'} value={address?.return_warehouse_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                    {addressFormError?.return_warehouse_address?.person_mobile && <div className="error">{addressFormError?.return_warehouse_address?.person_mobile}</div>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                Billing Place Address
                                <span className='copySameAsAbove'>
                                    <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={billingChecked.registeredOffice} onChange={handleCopyRegisteredOfficeAddressForBillingPlace} />
                                    <label className='form-check-label' htmlFor='sameAsBilling'>
                                        Same as Registered Office Address
                                    </label>
                                </span>
                                <span className='copySameAsAbove'>
                                    <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={billingChecked.warehouse} onChange={handleCopyWarehouseAddressForBillingPlace} />
                                    <label className='form-check-label' htmlFor='sameAsBilling'>
                                        Same as Warehouse Address
                                    </label>
                                </span>
                            </div>
                            <div className='formContainer addressesDetailsCont'>
                                <div className='inputBottomSpace' ref={refs.billing_address.address}>
                                    <InputTextField type='text' disabled={false} name={'billing_address.address'} value={address?.billing_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                    {addressFormError?.billing_address?.address && <div className="error">{addressFormError?.billing_address?.address}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.pincode}>
                                    <InputTextField type='text' disabled={false} name={'billing_address.pincode'} value={address?.billing_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                    {addressFormError?.billing_address?.pincode && <div className="error">{addressFormError?.billing_address?.pincode}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.area} >
                                    <InputTextField type='text' disabled={false} name={'billing_address.area'} value={address?.billing_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                    {addressFormError?.billing_address?.area && <div className="error">{addressFormError?.billing_address?.area}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.city}>
                                    <InputTextField type='text' name={'billing_address.city'} value={address?.billing_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                    {addressFormError?.billing_address?.city && <div className="error">{addressFormError?.billing_address?.city}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.region}>
                                    <InputTextField type='text' disabled={true} name={'billing_address.region'} value={address?.billing_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                    {addressFormError?.billing_address?.region && <div className="error">{addressFormError?.billing_address?.region}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.state} >
                                    <InputTextField type='text' disabled={true} name={'billing_address.state'} value={address?.billing_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                    {addressFormError?.billing_address?.state && <div className="error">{addressFormError?.billing_address?.state}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.email}>
                                    <InputTextField type='text' disabled={false} name={'billing_address.email'} value={address?.billing_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                    {addressFormError?.billing_address?.email && <div className="error">{addressFormError?.billing_address?.email}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.mobile}>
                                    <InputTextField type='text' disabled={false} name={'billing_address.mobile'} value={address?.billing_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Place Mobile Number' />
                                    {addressFormError?.billing_address?.mobile && <div className="error">{addressFormError?.billing_address?.mobile}</div>}
                                </div>
                                <div className='inputBottomSpace' ref={refs.billing_address.person_mobile}>
                                    <InputTextField type='text' disabled={false} name={'billing_address.person_mobile'} value={address?.billing_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                    {addressFormError?.billing_address?.person_mobile && <div className="error">{addressFormError?.billing_address?.person_mobile}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (activeStep === 5) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Tekx Contact Details
                </div>
                <div ref={fifthTabRef} className='pt-3 formContainer customScroll'>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_name}>
                        <InputTextField type='text' disabled={false} name={'contacts_name'} value={companyContactDetails?.contacts_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Contact Name' />
                        {companyContactDetailsFormError.contacts_name && <div className="error">{companyContactDetailsFormError.contacts_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_mobile}>
                        <InputTextField type='text' disabled={false} name={'contacts_mobile'} value={companyContactDetails?.contacts_mobile} onChange={handleCompanyContactDetailsChange} required minLength={10} maxLength={10} label='Mobile Number' />
                        {companyContactDetailsFormError.contacts_mobile && <div className="error">{companyContactDetailsFormError.contacts_mobile}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_email_id}>
                        <InputTextField type='text' disabled={false} name={'contacts_email_id'} value={companyContactDetails?.contacts_email_id} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Email Id' />
                        {companyContactDetailsFormError.contacts_email_id && <div className="error">{companyContactDetailsFormError.contacts_email_id}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_person_dept}>
                        <InputTextField type='text' disabled={false} name={'contacts_person_dept'} value={companyContactDetails?.contacts_person_dept} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Contact’s Person Department' />
                        {/* <CustomAutoComplete onChange={onChangeCOF} value={brandDetails?.status !== null ? (brandDetails.status ? 'Active' : 'Inactive') : ''} required options={STATUS} label='Contact’s Person Department*' /> */}
                        {companyContactDetailsFormError.contacts_person_dept && <div className="error">{companyContactDetailsFormError.contacts_person_dept}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.account_name}>
                        <InputTextField type='text' disabled={false} name={'account_name'} value={companyContactDetails?.account_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Account Name' />
                        {companyContactDetailsFormError.account_name && <div className="error">{companyContactDetailsFormError.account_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.bank_name}>
                        <InputTextField type='text' disabled={false} name={'bank_name'} value={companyContactDetails?.bank_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Bank Name' />
                        {companyContactDetailsFormError.bank_name && <div className="error">{companyContactDetailsFormError.bank_name}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.account_number}>
                        <InputTextField type='text' disabled={false} name={'account_number'} value={companyContactDetails?.account_number} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Account Number' />
                        {companyContactDetailsFormError.account_number && <div className="error">{companyContactDetailsFormError.account_number}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.ifsc_code}>
                        <InputTextField type='text' disabled={false} name={'ifsc_code'} value={companyContactDetails?.ifsc_code} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='IFSC Code' />
                        {companyContactDetailsFormError.ifsc_code && <div className="error">{companyContactDetailsFormError.ifsc_code}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.branch}>
                        <InputTextField type='text' disabled={false} name={'branch'} value={companyContactDetails?.branch} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Branch' />
                        {companyContactDetailsFormError.branch && <div className="error">{companyContactDetailsFormError.branch}</div>}
                    </div>
                    <div className='inputBottomSpace' ref={refs.companyContactDetails.branch_address}>
                        <InputTextField type='text' disabled={false} name={'branch_address'} value={companyContactDetails?.branch_address} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Branch Address' />
                        {companyContactDetailsFormError.branch_address && <div className="error">{companyContactDetailsFormError.branch_address}</div>}
                    </div>

                    {/* <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={false} name={'branch_pincode'} value={companyContactDetails?.branch_pincode} onChange={handleCompanyContactDetailsChange} required minLength={6} maxLength={6}  label='Branch PinCode' />
                        {companyContactDetailsFormError?.branch_pincode && <div className="error">{companyContactDetailsFormError?.branch_pincode}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={true} name={'branch_city'} value={companyContactDetails?.branch_city} required minLength={''} maxLength={''}  label='Branch City' />
                        {companyContactDetailsFormError?.branch_city && <div className="error">{companyContactDetailsFormError?.branch_city}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField type='text' disabled={true} name={'branch_state'} value={companyContactDetails?.branch_state} required minLength={''} maxLength={''}  label='Branch State' />
                        {companyContactDetailsFormError?.branch_state && <div className="error">{companyContactDetailsFormError?.branch_state}</div>}
                    </div> */}
                </div>
            </div>
        } else if (activeStep === 6) {
            return <div>
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Uploaded Documents
                </div>
                <div ref={sixthTabRef} className='documentUpload customScroll uploadedDocuments_cont'>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(panDocumentFile) ?
                            <CustomDropzone name={'PANDocument'} onFileDrop={(file) => handleFileDrop('PANDocument', file)} displayContent={<div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={ImportIcon} alt="" />
                                        </label>
                                    </div>
                                    <div>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload PAN Card</div>
                                        <div className='fontSize14 textColorGray'>
                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                        </div>
                                    </div>
                                </div>
                            </div>} />
                            :
                            <div style={{ width: '100%' }}>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={panDocumentFileTypeIcon} alt="" />
                                        </label>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload PAN Card</div>
                                        <div className='fontSize14 textColorGray'>
                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('PANDocument')} progress={panDocumentProgress} File={panDocumentFile} uploadSpeed={panDocumentUploadSpeed} notShowBrand={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(gstDocumentFile) ?
                            <CustomDropzone name={'GSTDocument'} onFileDrop={(file) => handleFileDrop('GSTDocument', file)} displayContent={<div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={ImportIcon} alt="" />
                                        </label>
                                    </div>
                                    <div>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload GST Document</div>
                                        <div className='fontSize14 textColorGray'>
                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                        </div>
                                    </div>
                                </div>
                            </div>} />
                            :
                            <div style={{ width: '100%' }}>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={renderFileIcons(gstDocumentFile)} alt="" />
                                        </label>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload GST Document</div>
                                        <div className='fontSize14 textColorGray'>
                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GSTDocument')} progress={gstDocumentProgress} File={gstDocumentFile} uploadSpeed={gstDocumentUploadSpeed} notShowBrand={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(agreementDocumentFile) ?
                            <CustomDropzone name={'AgreementDocument'} onFileDrop={(file) => handleFileDrop('AgreementDocument', file)} displayContent={<div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={ImportIcon} alt="" />
                                        </label>
                                    </div>
                                    <div>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload Agreement Document</div>
                                        <div className='fontSize14 textColorGray'>
                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                        </div>
                                    </div>
                                </div>
                            </div>} />
                            :
                            <div style={{ width: '100%' }}>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={renderFileIcons(agreementDocumentFile)} alt="" />
                                        </label>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload Agreement Document</div>
                                        <div className='fontSize14 textColorGray'>
                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('AgreementDocument')} progress={agreementDocumentProgress} File={agreementDocumentFile} uploadSpeed={agreementDocumentUploadSpeed} notShowBrand={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(uploadBrandFileLogo) ?
                            <CustomDropzone name={'brandLogo'} onFileDrop={(file) => handleFileDrop('brandLogo', file)} displayContent={<div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={ImportIcon} alt="" />
                                        </label>
                                    </div>
                                    <div>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload Brand Logo</div>
                                        <div className='fontSize14 textColorGray'>
                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                            <div className='pt-2'>JPEG, JPG or PNG</div>
                                        </div>
                                    </div>
                                </div>
                            </div>} />
                            :
                            <div style={{ width: '100%' }}>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                        <label className="cursorPointer">
                                            <img src={brandLogoFileTypeIcon} alt="" />
                                        </label>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div className='fontMedium textColorBlack fontSize16'>Upload Brand Logo</div>
                                        <div className='fontSize14 textColorGray'>
                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('brandLogo')} progress={brandLogoProgress} File={uploadBrandFileLogo} uploadSpeed={brandLogoUploadSpeed} notShowBrand={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* <div className='uploadCard'>
                        {isFileDetailsEmpty(brandFloorSpaceFile) ?
                        <CustomDropzone name={'BrandFloorSpace'} onFileDrop={(file) => handleFileDrop('BrandFloorSpace', file)} displayContent={<div>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={ImportIcon} alt="" />
                                    </label>
                                </div>
                                <div>
                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                    <div className='fontSize14 textColorGray'>
                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                    </div>
                                </div>
                            </div>
                        </div>} />
                        :
                        <div style={{width : '100%'}}>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={brandFloorSpaceFileTypeIcon} alt="" />
                                    </label>
                                </div>
                                <div style={{width : '100%'}}>
                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                    <div className='fontSize14 textColorGray'>
                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('BrandFloorSpace')} progress={brandFloorSpaceProgress} File={brandFloorSpaceFile} uploadSpeed={brandFloorSpaceUploadSpeed} notShowBrand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(adjacentFile) ?
                        <CustomDropzone name={'Adjacent'} onFileDrop={(file) => handleFileDrop('Adjacent', file)} displayContent={<div>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={ImportIcon} alt="" />
                                    </label>
                                </div>
                                <div>
                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                    <div className='fontSize14 textColorGray'>
                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                    </div>
                                </div>
                            </div>
                        </div>} />
                        :
                        <div style={{width : '100%'}}>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={adjacentFileTypeIcon} alt="" />
                                    </label>
                                </div>
                                <div style={{width : '100%'}}>
                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                    <div className='fontSize14 textColorGray'>
                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Adjacent')} progress={adjacentProgress} File={adjacentFile} uploadSpeed={adjacentUploadSpeed} notShowBrand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className='uploadCard'>
                        {isFileDetailsEmpty(otherFile) ?
                        <CustomDropzone name={'Other'} onFileDrop={(file) => handleFileDrop('Other', file)} displayContent={<div>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={ImportIcon} alt="" />
                                    </label>
                                </div>
                                <div>
                                    <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                    <div className='fontSize14 textColorGray'>
                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                    </div>
                                </div>
                            </div>
                        </div>} />
                        :
                        <div style={{width : '100%'}}>
                            <div className='d-flex align-items-center'>
                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                    <label className="cursorPointer">
                                        <img src={otherFileTypeIcon} alt="" />
                                    </label>
                                </div>
                                <div style={{width : '100%'}}>
                                    <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                    <div className='fontSize14 textColorGray'>
                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Other')} progress={otherProgress} File={otherFile} uploadSpeed={otherUploadSpeed} notShowBrand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div> */}
                </div>
            </div>
        }
    }
    // Input Form End

    return (
        <div>
            {displayFormContent()}
        </div>
    )
}

export default BrandInputForm;